import React from 'react';
import './Header.css';

import PikaText from '../../assets/LogoText.svg';
import {Link} from 'react-router-dom';

const Header = () => {
  return (
    <div className="header-section">
      <button className="btn-contact"><a href='#contact'>צור קשר</a></button>
      <Link to='/'><img src={PikaText} alt="pika" /></Link>
    </div>
  );
};

export default Header;

