import React,{useState} from 'react';
import './Contact.css';
import ContactImage from '../../assets/ContactImage-min.png';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import axios from 'axios'
const SendGrid = require('@sendgrid/mail');
SendGrid.setApiKey(process.env.SENDGRID_API_KEY);

const Contact = () => {

    const phoneRegex = /^\+?(972|0)(\-)?0?(([23489]{1}\d{7})|[57]{1}\d{8})$/; //eslint-disable-line
    const [submitted, setSubmitted] = useState(false);
    const [errorEmail, setErrorEmail] = useState(false);

    const contactSchema = Yup.object().shape({
        email: Yup.string().email('אימייל לא תיקני').required('חובה להזין אימייל'),
        fname: Yup.string().required('חובה להזין שם פרטי'),
        lname: Yup.string().required('חובה להזין שם משפחה'),
        phone: Yup.string().matches(phoneRegex,"טלפון לא תיקני"),
    });


    return (
        <div className="contact section" id="contact">
            <div className="contact-form">
                <h2>צור קשר</h2>
                <h4>אם יש לכם שאלות, הצעות, משוב שתרצו לחלוק איתנו, אנא מלאו את הטופס למטה.</h4>


                <Formik
                initialValues={{
                  formType: 'contact',
                  fname: '',
                  lname: '',
                  email: '',
                  phone: '',
                  msg: '',
                }}
                validationSchema={contactSchema}
                onSubmit={(values) => {
                  const headers = {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET, POST',
                    'Access-Control-Allow-Headers': '*',
                    'api-key': 'e05569c9-c10b-4922-8b94-0a962c1bb22e'
                  }
                  const urli = 'https://api.pikadelivery.com/contact-form';
                  //send the object to email
                  axios.post(urli,{...values},{headers:headers}).then((response) =>{console.log(response)}).catch((error) => {setSubmitted(false); setErrorEmail(true)});
                  setTimeout(function(){
                    if(!errorEmail){
                      setSubmitted(true);
                    } 
                 }, 3500);
                }}
              >
                {({ errors, touched }) => (
                  <Form>
                  <p>
                  {errors.fname && touched.fname ? (
                      <div><span className="red">{errors.fname}</span></div>
                    ) : null}
          
                    {errors.lname && touched.lname ? (
                      <div><span className="red">{errors.lname}</span></div>
                    ) : null}
          
                    {errors.email && touched.email ? <div><span className="red">{errors.email}</span></div> : null}
          
                    {errors.phone && touched.phone ? <div><span className="red">{errors.phone}</span></div> : null}
                  
                  </p>

                  <div className="contact-form form-group" id="contact">
                  <Field name="fname" placeholder="שם פרטי" /><br />

  
                  <Field name="lname" placeholder="שם משפחה"/><br />

                  </div>
  
  
                  <div className="contact-form form-group">
                  <Field name="email" type="email" placeholder="כתובת אימייל" /><br />

                    <Field name="phone" placeholder="מספר טלפון" /><br />

                  </div>
  
                  
                  <div className="contact-form form-group textarea">
                      <label>הודעה</label>
                      <Field name="msg" rows='8' as="textarea"></Field>
                  </div>
                  <div className="contact-form form-group submit">

                  <button className="btn btn-submit" type="submit" disabled={submitted}>שלח</button><br />
                  {submitted && 'הטופס נשלח בהצלחה'}
  
                  </div>
                  </Form>
                )}
              </Formik>


            </div>
            <div className="contact image">
                <img src={ContactImage} alt='contact us' />

            </div>
        </div>
    );
}

export default Contact;
