import { Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import {Helmet} from "react-helmet";

import './index.css';
import Home from './pages/Home';
import Header from './components/Header/Header';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import Footer from './components/Footer/Footer';
import DeliveryModal from './components/DeliveryModal/DeliveryModal.jsx';
import Scroll from './components/ScrollToTop';
import {Container} from 'reactstrap';

const hist = createBrowserHistory();

const App = () => {


console.log(process.env)

  return (
    <Router history={hist}>
    <Helmet>
    <title>Pika - Home</title>
    </Helmet>
      <Header />
      <Scroll />
      <Container>
      <Switch>
        <Route exact path={'/'} component={Home} />
        <Route exact path={'/Delivery/:form'} component={DeliveryModal} />
        <Route exact path={'/terms'} component={Terms} />
        <Route exact path={'/privacy'} component={Privacy} />
      </Switch>
      </Container>
      <Footer />

    </Router>
  );
};

export default App;
