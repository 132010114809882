import background from '../background.svg';

const Privacy = () => {
  return (
    <div style={styles.container}>
      <div style={styles.innerContainer}>
        <p dir="rtl">
          <br />
          <strong>PIKA – </strong>
          <strong>מדיניות</strong>
          <strong> </strong>
          <strong>הפרטיות</strong>
          <strong> </strong>
          <strong>בשירות</strong>
        </p>
        <p dir="rtl">
          <br />
          <strong>מהי</strong>
          <strong> </strong>
          <strong>מדיניות</strong>
          <strong> </strong>
          <strong>הפרטיות</strong>
          <strong>?</strong>
        </p>
        <p dir="rtl">
          חברת פיקה שירותי שליחויות בע"מ, ח.פ. 516373560 (" <strong>מפעיל השירות</strong>") מתייחסת
          בכבוד לפרטיות משתמשי שירות המשלוחים וההזמנות <strong>"PIKA"</strong>, שהיא מפעילה באמצעות
          יישומון (אפליקציה) למכשירי קצה חכמים, או באמצעות דרכי גישה מקוונות אחרות.
        </p>
        <p dir="rtl">
          תנאים אלה ילמדו אותך מהי מדיניות הפרטיות הנוהגת בשירות. הם סוקרים, בין השאר, את האופן שבו
          משתמש מפעיל השירות במידע הנמסר לו על-ידי משתמשי השירות - בעת הרישום לשירות, בעת ביצוע
          הזמנת מוצרים באמצעות השירות, בעת השימוש השוטף בשירות, או בכל דרך (כגון כשאתה יוצר קשר עם
          שירות הלקוחות שלנו).
        </p>
        <p dir="rtl">
          מדיניות זו מהווה חלק בלתי נפרד <strong>מתנאי השימוש בשירות</strong>
          [Pikadelivery.com/terms]. המדיניות מנוסחת בלשון זכר מטעמי נוחות בלבד, והיא מתייחסת, כמובן,
          גם לכל המינים.
        </p>
        <p dir="rtl">
          <br />
          <strong>צור</strong>
          <strong> </strong>
          <strong>קשר</strong>
          <strong> / </strong>
          <strong>מסור</strong>
          <strong> </strong>
          <strong>דיווח</strong>
          <strong> </strong>
          <strong>על</strong>
          <strong> </strong>
          <strong>פגיעה</strong>
          <strong> </strong>
          <strong>בפרטיותך</strong>
        </p>
        <p dir="rtl">
          במידה ואתה סבור כי פרטיותך נפגעה במהלך השימוש בשירות, או בכל שאלה אחרת הנוגעת למדיניות זו,
          אנא פנה למפעיל השירות באמצעות כתובת הדוא"ל: info@pikadelivery.com. נציגינו עומדים לרשותך
          לצורך כל בקשה, תגובה, שאלה או תלונה.
        </p>
        <p dir="rtl">
          <br />
          <strong>מסירת</strong>
          <strong> </strong>
          <strong>פרטים</strong>
          <strong> </strong>
          <strong>ומידע</strong>
          <strong> </strong>
          <strong>אישי</strong>
          <strong> </strong>
          <strong>למפעיל</strong>
          <strong> </strong>
          <strong>השירות</strong>
        </p>
        <p dir="rtl">
          השימוש בשירות והזמנת מוצרים מבתי עסק באמצעותו מחייבים רישום ומסירת המידע המתבקש במהלך
          הרישום ובמהלך כל הזמנה. לא ניתן להשתמש בשירות ללא השלמת הליך הרישום. בכל עת תוכל לעדכן את
          פרטייך האישיים באמצעות חשבונך האישי וממשק היישומון.
        </p>
        <p dir="rtl">
          <br />
          <strong>הרישום</strong>
          <strong> </strong>
          <strong>לשירות</strong>
          <strong>, </strong>
          <strong>השימוש</strong>
          <strong> </strong>
          <strong>בו</strong>
          <strong> </strong>
          <strong>והזמנת</strong>
          <strong> </strong>
          <strong>מוצרים</strong>
          <strong> </strong>
          <strong>מבתי</strong>
          <strong> </strong>
          <strong>העסק</strong>
          <strong> </strong>
          <strong>באמצעותו</strong>
          <strong>, </strong>
          <strong>מותרים</strong>
          <strong> </strong>
          <strong>למשתמשים</strong>
          <strong> </strong>
          <strong>בגירים</strong>
          <strong>, </strong>
          <strong>בני</strong>
          <strong> 18 </strong>
          <strong>ומעלה</strong>
          <strong> </strong>
          <strong>בלבד</strong>
          <strong>, </strong>
          <strong>לכל</strong>
          <strong> </strong>
          <strong>תאגיד</strong>
          <strong> </strong>
          <strong>המאוגד</strong>
          <strong> </strong>
          <strong>כדין</strong>
          <strong> </strong>
          <strong>בישראל</strong>
          <strong>, </strong>
          <strong>או</strong>
          <strong> </strong>
          <strong>לכל</strong>
          <strong> </strong>
          <strong>אישיות</strong>
          <strong> </strong>
          <strong>משפטית</strong>
          <strong> </strong>
          <strong>כשירה</strong>
          <strong> </strong>
          <strong>אחרת</strong>
          <strong>. </strong>
          <strong>השימוש</strong>
          <strong> </strong>
          <strong>בשירות</strong>
          <strong> </strong>
          <strong>אינו</strong>
          <strong> </strong>
          <strong>מותר</strong>
          <strong> </strong>
          <strong>לקטינים</strong>
          <strong> </strong>
          <strong>שגילם</strong>
          <strong> </strong>
          <strong>נמוך</strong>
          <strong> </strong>
          <strong>מ</strong>
          <strong>-18. </strong>
          <strong>אם</strong>
          <strong> </strong>
          <strong>גילך</strong>
          <strong> </strong>
          <strong>נמוך</strong>
          <strong> </strong>
          <strong>מ</strong>
          <strong>-18 </strong>
          <strong>אתה</strong>
          <strong> </strong>
          <strong>מתבקש</strong>
          <strong> </strong>
          <strong>להימנע</strong>
          <strong> </strong>
          <strong>מהשימוש</strong>
          <strong> </strong>
          <strong>בשירות</strong>
          <strong> </strong>
          <strong>ולא</strong>
          <strong> </strong>
          <strong>למסור</strong>
          <strong> </strong>
          <strong>כל</strong>
          <strong> </strong>
          <strong>מידע</strong>
          <strong> </strong>
          <strong>אישי</strong>
          <strong> </strong>
          <strong>למפעיל</strong>
          <strong> </strong>
          <strong>השירות</strong>
          <strong>.</strong>
        </p>
        <p dir="rtl">
          בעת הרישום לשירות יהיה עליך להזין את מספר הטלפון הנייד שלך. מספר זה ישמש אותנו לצורך אימות
          וזיהוי שלך קודם לתחילת הרישום, באמצעות משלוח קוד אימות במסרון (SMS), שאותו יהיה עלייך
          להזין ביישומון. לאחר השלמת הליך האימות, תתבקש להזין פרטים אישיים ופרטי יצירת קשר איתך,
          כגון, שמך המלא, כתובת דוא"ל פעילה, כתובת למשלוחים ועוד.
        </p>
        <p dir="rtl">
          אתה עשוי להידרש מעת לעת להזין בגוף היישומון את פרטי הרישום שמסרת למפעיל השירות, וזאת לצורך
          התחברות מחודשת לשירות במקרה של יציאה/ניתוק ממנו.
        </p>
        <p dir="rtl">
          בהליך הזמנת המוצרים מבתי העסק בשירות יהיה עליך להזין את המידע והפרטים האישיים הנדרשים.
          פרטים אלה עשויים לכלול את שמך המלא, שיטת המשלוח (ובמקרה של איסוף עצמי – בחירת הסניף
          לאיסוף), כתובת מדויקת למשלוח, כתובת דוא"ל פעילה, טלפון, וכל פרט נוסף שאנו עשויים לדרוש
          במהלך ההזמנה, כגון מידע בנוגע לפעולה אותה מתבקש השליח לבצע במידה והנמען אינו בבית. מפעיל
          השירות שומר תיעוד של היסטוריית ההזמנות שלך באמצעות השירות.
        </p>
        <p dir="rtl">
          לפי שיקול דעתנו, אנו עשויים לדרוש ממך מסירת פרטים כאמור הן ביחס למבצע הרכישה והן ביחס
          לפרטי האדם / הגוף אליו יישלחו המוצרים שהזמנת. תוכל גם להוסיף הערות ובקשות מיוחדות בגוף
          היישומון.
        </p>
        <p dir="rtl">
          התשלום עבור המוצרים שתרכוש מבתי העסק יתבצע באמצעות כרטיס אשראי. עליך למסור בגוף היישומון
          את מספר כרטיס האשראי, תעודת הזהות של בעל הכרטיס, סוג הכרטיס, תוקפו וכל פרט נוסף הדרוש לנו
          לצורך השלמת הליך ההזמנה (כגון הספרות שבגב הכרטיס). מפעיל השירות סולק את העסקאות בשירות
          באמצעות שירותי חברת PayMe [https://www.paymeservice.com]. פרטי אמצעי התשלום שתמסור לצורך
          ביצוע ההזמנה אינם נשמרים בידי מפעיל השירות אלא בידי ספק שירותי הסליקה בלבד.
        </p>
        <p dir="rtl">
          במידה ותרצה לפנות למפעיל השירות באמצעות טופס יצירת הקשר [Pikadelivery.com/contact] בגוף
          השירות, תידרש למסור לנו את פרטי הקשר הנדרשים בשדות החובה של הטופס וכן להזין את תוכן פנייתך
          במקום המיועד לכך. אנו עשויים לאסוף מידע אישי נוסף שתמסור לנו בכל מקרה בו תיצור קשר עם
          שירות הלקוחות שלנו.
        </p>
        <p dir="rtl">
          השדות שחובה למלא יסומנו במפורש. ללא מסירת המידע המבוקש לא תוכל להשלים את הרישום או את
          ההזמנה המבוקשת.
        </p>
        <p dir="rtl">
          עליך למסור רק פרטים נכונים, מדויקים ומלאים. <strong>הפרטים</strong>
          <strong> </strong>
          <strong>והמידע</strong>
          <strong> </strong>
          <strong>שתמסור</strong>
          <strong> </strong>
          <strong>יישמרו</strong>
          <strong> </strong>
          <strong>במאגר</strong>
          <strong> </strong>
          <strong>המידע</strong>
          <strong> </strong>
          <strong>שבבעלות</strong>
          <strong> </strong>
          <strong>מפעיל</strong>
          <strong> </strong> <strong>השירות</strong>
          <strong> </strong>(למעט פרטי אמצעי התשלום, אותם איננו שומרים כאמור לעיל)
          <strong>. אינך חייב על-פי חוק למסור את הפרטים והדבר תלוי ברצונך ובהסכמתך</strong>, אולם
          פרטים שגויים, או אי מסירת מלוא הפרטים הנדרשים, עלולים למנוע ממך את האפשרות להשתמש בשירות,
          לפגוע באיכות השירות הניתן לך וכן לפגוע ביכולת ליצור איתך קשר במידת הצורך או למסור לך את
          הזמנותייך. <strong>השימוש במידע ובפרטים שתמסור יהיה כמפורט במדיניות זו להלן</strong>.
        </p>
        <p dir="rtl">
          כאשר אתה מוסר פרטים אישיים של צד שלישי, אתה מצהיר ומאשר כי קיבלת את הסכמתו המפורשת ומדעת
          למסירת פרטיו למפעיל השירות, לשם השימוש בפרטים אלה בהתאם למדיניות זו.
        </p>
        <p dir="rtl">
          <br />
          <strong>מידע</strong>
          <strong> </strong>
          <strong>מבוסס</strong>
          <strong> </strong>
          <strong>מיקום</strong>
          <strong> </strong>
          <strong>שאנו</strong>
          <strong> </strong>
          <strong>אוספים</strong>
        </p>
        <p dir="rtl">
          חלק מהותי בשירות וביישומון הוא שירותי איתור מבוססי מיקום, הנעזרים בטכנולוגיית GPS על מנת
          לקבוע את מיקומך כמשתמש בשירות, באמצעות מכשיר הקצה שלך, לצורך השימוש במיקומך על-מנת לאתר את
          מקום ההזמנה ו/או מקום קבלת משלוח המוצרים, וכן על-מנת לנסות ולחשב, עד כמה שהדבר מתאפשר, את
          זמן ההגעה של שליחי PIKA בעת הזמנת משלוח, וכן על-מנת לאפשר לך לעקוב בזמן אמת אחר מסלול
          השליח עד להגעתו ליעדו. לא ניתן לספק את השירות ללא הסכמתך לשימוש בטכנולוגיה זו. מפעיל
          השירות עשוי גם להשתמש במידע על מיקומך על מנת ליצור מידע מצרפי אנונימי והוא ישתמש במידע זה
          בהתאם למדיניות פרטיות זו להלן.
        </p>
        <p dir="rtl">
          <br />
          <strong>מידע</strong>
          <strong> </strong>
          <strong>שנאסף</strong>
          <strong> </strong>
          <strong>במהלך</strong>
          <strong> </strong>
          <strong>השימוש</strong>
          <strong> </strong>
          <strong>בשירות</strong>
        </p>
        <p dir="rtl">
          בעת שאתה משתמש בשירות, יאסוף מפעיל השירות מידע על נוהגיך ביחס לשימוש בשירות, לרבות בתי עסק
          שמצאת בהם עניין, מידע או פרסומות שקראת, העמודים שבהם צפית, שאילתות חיפוש שביצעת, השירותים
          שעניינו אותך ותכיפות השימוש בהם, מקום מכשיר הקצה שלך ונתוני כתובת האינטרנט שבאמצעותה ניגשת
          לשירות, סוג מערכת ההפעלה המשמשת אותך, סוג מכשיר קצה שברשותך ומידע אודותיו, ועוד. בנוסף,
          מפעיל השירות רשאי לאסוף ולהסתייע בשירותיהם של צדדים שלישיים כדי לאסוף ולנתח מידע אנונימי,
          סטטיסטי, או מצרפי (אגרגטיבי) בקשר עם השימוש בשירות, לרבות מידע הנוגע לפעילותך בשירות. מידע
          זה נאסף לרוב באופן אוטומטי.
        </p>
        <p dir="rtl">
          <br />
          <strong>השימוש</strong>
          <strong> </strong>
          <strong>במידע</strong>
        </p>
        <p dir="rtl">
          בפרטים ובמידע האישי שתמסור ובפרטים שיאסוף מפעיל השירות במהלך השימוש שתעשה בשירות, ייעשה
          שימוש בהתאם להוראות מדיניות זו או לפי הוראות כל דין, למטרות הבאות -
        </p>
        <p dir="rtl">כדי לאפשר לך להשתמש בשירות ולהזמין מוצרים מבתי עסק באמצעותו;</p>
        <p dir="rtl">כדי לזהות אותך במהלך כניסות חוזרות לשירות;</p>
        <p dir="rtl">
          כדי לשפר ולהעשיר את השירות והתכנים המוצעים בשירות, כמו גם מבתי העסק המוכרים בו מוצרים,
          ובכלל זה ליצור שירותים ותכנים חדשים המתאימים לדרישות המשתמשים וציפיותיהם וכן לשנות או לבטל
          שירותים ותכנים קיימים. המידע שישמש את מפעיל השירות לצורך כך יהיה בעיקרו מידע סטטיסטי,
          שאינו מזהה אותך אישית;
        </p>
        <p dir="rtl">כדי לאפשר לך להתאים את השירות להעדפותיך;</p>
        <p dir="rtl">כדי לגבות ממך את התמורה עבור הזמנת מוצרים ושירותים;</p>
        <p dir="rtl">
          כדי לשלוח אליך מעת לעת מידע בדבר השירות, סקרים, עלוני מידע, כמו גם מידע פרסומי ומידע בקשר
          עם מוצרים ושירותים של מפעיל השירות ושל צדדים שלישיים כגון מפרסמים. מידע כזה ישוגר אליך
          בהתאם להסכמה המפורשת שניתנה על-ידך עת אישרת את ההסכמים המחייבים, או בכל דרך אחרת. תוכל
          לבטל את הסכמתך בכל עת ולחדול מקבלתם של דברי פרסומת. מובהר כי הסכמה זו מהווה הסכמה לצורך
          הוראות סעיף 30א לחוק התקשורת (בזק ושירותים), התשמ"ב-1982, כמפורט בהרחבה
          <strong>בתנאי השימוש של השירות</strong>; [ <strong>Pikadelivery.com/terms</strong>]
        </p>
        <p dir="rtl">ליצירת קשר איתך כשמפעיל השירות סבור שקיים צורך בכך;</p>
        <p dir="rtl">
          לצורך ניתוח, בקרה ומסירת מידע סטטיסטי לצדדים שלישיים. מידע זה לא יזהה אותך אישית;
        </p>
        <p dir="rtl">
          לשם תפעולו התקין ופיתוחו של השירות, כמו גם ניהול הקשר עם בתי העסק מהם ביצעת הזמנות;
        </p>
        <p dir="rtl">
          כדי לאכוף את תנאי השימוש בשירות, על-מנת למלא אחר דרישות כל חוק, תקנה או דבר חקיקה אחר וכן
          על-מנת לסייע לרשויות וערכאות מוסמכות, או לכל צד שלישי, כשמפעיל השירות סבורה בתום לב כי
          עליו לעשות כן;
        </p>
        <p dir="rtl">לכל מטרה אחרת, המפורטת במדיניות זו או בתנאי השימוש של השירות.</p>
        <p dir="rtl">
          <br />
          <strong>מסירת</strong>
          <strong> </strong>
          <strong>מידע</strong>
          <strong> </strong>
          <strong>לצדדים</strong>
          <strong> </strong>
          <strong>שלישיים</strong>
        </p>
        <p dir="rtl">
          מפעיל השירות לא יעביר לצדדים שלישיים את המידע האישי שמסרת ואת המידע שנאסף על פעילותך
          בשירות, אלא במקרים המפורטים להלן -
        </p>
        <p dir="rtl">
          ככל שהדבר דרוש לצורך אספקה תקינה של השירות ובכלל זה לבתי העסק מהם הזמנת, אולם לבתי העסק
          יימסר אך ורק המידע הדרוש לצורך מימוש הזמנתך של מוצרים מאותם בתי עסק;
        </p>
        <p dir="rtl">
          אם תפר את הוראות המסמכים המחייבים, או אם תבצע באמצעות השירות, או בקשר עמו, פעולות הנחזות
          על ידי מפעיל השירות כמנוגדות לדין או ניסיון לבצע פעולות כאלה;
        </p>
        <p dir="rtl">
          אם יתקבל בידי מפעיל השירות צו שיפוטי, או דרישה של רשות המוסמכת לכך, המורה לו למסור את
          פרטיך או את המידע אודותיך לצד שלישי;
        </p>
        <p dir="rtl">
          בכל מחלוקת, טענה, תביעה, דרישה או הליכים משפטיים, אם יהיו, בינך לבין מפעיל השירות או מי
          מטעמו;
        </p>
        <p dir="rtl">
          בכל מקרה שמפעיל השירות יסבור כי מסירת המידע נחוצה כדי למנוע נזק חמור לגופך או לרכושך או
          לגופו או לרכושו של צד שלישי;
        </p>
        <p dir="rtl">
          לחברות או לארגונים אחרים הקשורים למפעיל השירות, דוגמת חברת-אם, חברה-בת וחברה אחות, ככל
          ויהיו, ובלבד שהם ישתמשו במידע זה רק על-פי הוראות מדיניות פרטיות זו;
        </p>
        <p dir="rtl">
          לצורך מסירת ושיתוף מידע אנונימי, מצרפי וסטטיסטי עם חברות או ארגונים אחרים הקשורים במפעיל
          השירות וכן עם ספקים, שותפים עסקיים, מפרסמים וכל צד שלישי. מפעיל השירות לא יחשוף בפני צדדים
          שלישיים אלה ביודעין את זהותך ללא הסכמתך;
        </p>
        <p dir="rtl">
          אם מפעיל השירות יארגן את פעילותו או את פעילות השירות במסגרת אחרת וכן במקרה שישנה את המבנה
          המשפטי, או יתמזג עם גוף אחר, או ימזג את פעילות השירות עם פעילותו של צד שלישי - הוא יהיה
          זכאי להעביר לגוף החדש העתק מן המידע שנאגר אודותיך במאגר המידע של מפעיל השירות ובלבד שגוף
          זה יקבל על עצמו כלפיך את הוראות מדיניות פרטיות זו;
        </p>
        <p dir="rtl">על-פי בקשתך המפורשת.</p>
        <p dir="rtl">
          <br />
          <strong>היכן</strong>
          <strong> </strong>
          <strong>נשמר</strong>
          <strong> </strong>
          <strong>המידע</strong>
          <strong>?</strong>
        </p>
        <p dir="rtl">
          המידע שמפעיל השירות אוסף נשמר במאגר המידע שלו, המאוחסן אצל ספקי אירוח שרתים וגיבוי מידע,
          שעשויים להימצא גם מחוץ לגבולות ישראל. מידע נוסף, כפי שמפורט במדיניות זו, נאסף ונשמר על ידי
          חברות אחרות (כדוגמת חברות סטטיסטיקה וכיו"ב). אף הן יכולות לשמור את המידע מחוץ לגבולות
          המדינה. הסכמתך למדיניות זו מהווה גם הסכמה לשמירת המידע בחו"ל ולהעברתו מחוץ לגבולות ישראל,
          במידת הצורך.
        </p>
        <p dir="rtl">
          <br />
          <strong>Cookies </strong>
        </p>
        <p dir="rtl">
          מפעיל השירות עשוי להשתמש בשירות ב'עוגיות' (Cookies) לצורך תפעולו השוטף, לרבות כדי לאסוף
          נתונים סטטיסטיים אודות השימוש בשירות, לאימות פרטים, כדי להתאים את השירות להעדפותיך
          האישיות, לצרכי אבטחת מידע ועוד.
        </p>
        <p dir="rtl">
          'עוגיות' (Cookies) הן קבצי טקסט הנוצרים בעת השימוש בשירות. חלק מהעוגיות יפקעו כאשר תסגור
          את היישומון ואחרות נשמרות על גבי מכשיר הקצה שלך. ה-Cookies יכולות להכיל מידע מגוון כדוגמת
          העמודים שבהם ביקרת, משך הזמן ששהית בשירות, מידע שאתה מבקש לראות בעת הכניסה לשירות ועוד. אם
          אינך רוצה לקבל Cookies, תוכל להימנע מכך על ידי שינוי ההגדרות במכשיר הקצה שלך.
        </p>
        <p dir="rtl">
          <br />
          <strong>אבטחת</strong>
          <strong> </strong>
          <strong>מידע</strong>
        </p>
        <p dir="rtl">
          מפעיל השירות מייחס חשיבות עליונה לאבטחת המידע במערכותיו. לשם שמירת המידע, הוא מפעיל
          מערכות, יישומים ונהלים לאבטחת המידע, המיועדים למזער את הסיכונים מפני גניבה, פגיעה, אובדן
          או גישה בלתי מורשית למידע. עם זה, אין וודאות שיישומים אלה מבטיחים באופן מוחלט שמידע אישי
          לא ייחשף או ייגנב ממאגרי המידע. לכן, מפעיל השירות אינו מתחייבת שהשירות ומערכות המידע
          המשמשות את מפעיל השירות יהיו חסינים מפני גישה בלתי-מורשית למידע המאוחסן בהם. בעצם השימוש
          בשירות, אתה מודע ומסכים למגבלות אלו.
        </p>
        <p dir="rtl">
          <br />
          <strong>זכות</strong>
          <strong> </strong>
          <strong>לעיין</strong>
          <strong> </strong>
          <strong>במידע</strong>
        </p>
        <p dir="rtl">
          לפי חוק הגנת הפרטיות, כל אדם זכאי לעיין בעצמו, או על ידי בא-כוחו שהרשהו בכתב, או על ידי
          אפוטרופוס, במידע שעליו המוחזק במאגר מידע. אדם שעיין במידע שעליו ומצא שאינו נכון, שלם, ברור
          או מעודכן, רשאי לפנות לבעל מאגר המידע בבקשה לתקן את המידע או למחוק אותו. אם בעל המאגר
          סירב, עליו להודיע על כך למבקש באופן ובדרך שנקבעו בתקנות שמכוח חוק הגנת הפרטיות. על סירובו
          של בעל מאגר מידע לאפשר עיון ועל הודעת סירוב לתקן או למחוק מידע, רשאי מבקש המידע לערער
          באופן ובדרך שנקבעו בתקנות.
        </p>
        <p dir="rtl">
          מחיקת מידע עלולה למנוע ממך להמשיך ולהשתמש בשירות ולהביא לביטול רישומך אליו. מידע הדרוש
          למפעיל השירות - לרבות תיעוד פעולות שביצעת בשירות - יוסיף להישמר על ידי מפעיל השירות על-פי
          דין, אך לא ישמש עוד לצורך פניות אליך. אם בתוך 30 יום לא תקבל הודעה כי המידע שנתבקש מפעיל
          השירות למחוק אכן נמחק על-פי סעיף זה, תהיה זכאי לפנות לבית משפט באופן הקבוע בתקנות שמכוח
          חוק הגנת הפרטיות, כדי שיורה למפעיל השירות לפעול כאמור.
        </p>
        <p dir="rtl">
          <br />
          <strong>שינויים</strong>
          <strong> </strong>
          <strong>במדיניות</strong>
          <strong> </strong>
          <strong>הפרטיות</strong>
        </p>
        <p dir="rtl">
          מפעיל השירות רשאי לשנות מעת לעת את הוראות מדיניות הפרטיות. במידת האפשר ואם יבוצעו במדיניות
          זו שינויים מהותיים, תפורסם על-כך הודעה בממשק היישומון. הקישור למדיניות הפרטיות המוצב
          בשירות יקשר תמיד לנוסח העדכני של המדיניות. המשך שימושך בשירות לאחר פרסום מדיניות הפרטיות
          המעודכנת, מעיד על הסכמתך לה. אם אינך מסכים עם ההוראות החדשות במדיניות הפרטיות, עלייך לחדול
          מלעשות כל שימוש בשירות.
        </p>
        <p dir="rtl">
          <br />
          <strong>עודכן</strong>
          <strong> </strong>
          <strong>לאחרונה</strong>
          <strong>: 25.04.2021</strong>.
        </p>
      </div>
    </div>
  );
};

export default Privacy;

const styles = {
  text: {
    color: 'white',
    fontSize: 150,
  },
  container: {
    backgroundImage: `url(${background})`,
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    justifyContent: 'center',
  },
  innerContainer: {
    paddingTop: 100,
    maxWidth: 900,
  },
};
