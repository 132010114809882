import background from '../background.svg';

const Terms = () => {
  return (
    <div style={styles.container}>
      <div style={styles.innerContainer}>
        <p dir="rtl">
          <br />
          <strong>PIKA - </strong>
          <strong>תנאי</strong>
          <strong> </strong>
          <strong>שימוש</strong>
          <strong> </strong>
          <strong>בשירות</strong>
        </p>
        <p dir="rtl">
          <br />
          <strong>הקדמה</strong>
        </p>
        <p dir="rtl">
          <br />
          <strong>ברוכים</strong>
          <strong> </strong>
          <strong>הבאים</strong>
          <strong> </strong>
          <strong>ל</strong>
          <strong>-"PIKA" – </strong>
          שירות משלוחי מזון, משקה ומוצרים נוספים מבתי עסק בעיר נצרת וסביבתה ("{' '}
          <strong>השירות</strong>").
        </p>
        <p dir="rtl">
          שירותי "<strong>PIKA</strong>" ניתנים באמצעות יישומון (אפליקציה) למכשירי קצה חכמים, או
          באמצעות דרכי גישה מקוונות אחרות לפי שיקול דעת מפעיל השירות. את השירות מנהלת ומפעילה חברת
          פיקה שירותי שליחויות בע"מ, ח.פ. 516373560, שכתובתה ברחוב 1006 מספר 35, נצרת 1623729 ("
          <strong>מפעיל</strong> <strong> </strong>
          <strong>השירות</strong>").
        </p>
        <p dir="rtl">
          תנאים אלה מפרטים את זכויותיך וחובותיך בעת השימוש בשירות, ובעת הזמנת כל מוצר שבתי העסק
          המציגים בשירות מציעים באמצעותו ("<strong>המוצרים</strong>" ו" <strong>בתי</strong>
          <strong> </strong>
          <strong>העסק</strong>" בהתאמה).
        </p>
        <p dir="rtl">
          השימוש בשירות, וכל הזמנה שתבצע באמצעותו מבתי העסק, מעידים על הסכמתך לתנאים אלה ולתנאים
          נוספים המופיעים בשירות, לרבות <strong>מדיניות</strong> <strong> </strong>
          <strong>הפרטיות</strong> [Pikadelivery.com/privacy] וכן הוראות או הנחיות נוספות שייתכן
          ויוצגו בפנייך במהלך השימוש בשירות (יחדיו: " <strong>המסמכים</strong>
          <strong> </strong>
          <strong>המחייבים</strong>").
        </p>
        <p dir="rtl">
          אתה מתבקש לקרוא את המסמכים המחייבים בקפידה, שכן הם מהווים הסכם מחייב בינך לבין מפעיל
          השירות. תשומת לבך כי המסמכים המחייבים עשויים להשתנות, כאמור בתנאים אלה להלן, ולכן אנו
          ממליצים להתעדכן בהם מעת לעת. אם אינך מסכים לתנאי המסמכים המחייבים, כולם או חלקם, עלייך
          להימנע מהשימוש בשירות ומהזמנת מוצרים באמצעותו.
        </p>
        <p dir="rtl">
          המסמכים המחייבים חלים על השימוש בשירות באמצעות כל מחשב או מכשיר תקשורת אחר (כדוגמת טלפונים
          ושעונים חכמים, מחשבי לוח וכיו"ב). המסמכים המחייבים מנוסחים בלשון זכר מטעמי נוחות בלבד, והם
          מתייחסים, כמובן, לכל המינים.
        </p>
        <p dir="rtl">
          ביצוע הזמנות וקבלת ושליחת מידע בזמן אמת באמצעות השירות דורשים חיבור מקוון של מכשיר הקצה
          שברשותך לרשת האינטרנט. עלויות חיבור כאמור כפופות להסכמים שבינך לבין ספקי התקשורת שלך
          ולתשלומים החלים עליך.
        </p>
        <p dir="rtl">
          <br />
          <strong>קצת</strong>
          <strong> </strong>
          <strong>על</strong>
          <strong> PIKA</strong>
        </p>
        <p dir="rtl">
          השירות מהווה פלטפורמה מקוונת המאפשרת למשתמשי השירות להזמין משלוח, או איסוף עצמי, של מוצרים
          שמציעים בתי עסק שונים שבחרו להציג את מוצריהם באמצעות השירות (מוצרים אלה כוללים דברי מזון,
          משקה ומוצרים רבים נוספים).
        </p>
        <p dir="rtl">
          השירות מאפשר לבתי העסק המציגים בו להקים עמוד לבית העסק הכולל מידע על המוצרים שמציע בית
          העסק, תכונותיהם ומחירם, וכן מידע נוסף על בית העסק, לרבות מיקומו, שעות פעילות, תחומי
          התמחות, זמני המשלוח מרביים ועוד. בנוסף, השירות מסייע לאתר בתי עסק המציגים בו את מוצריהם,
          תוך שימוש באפשרויות וחתכי חיפוש מתקדמים ובהתאם לקטגוריות שונות של בתי עסק המפרסמים בו.
        </p>
        <p dir="rtl">
          <br />
          <strong>שים</strong>
          <strong> </strong>
          <strong>לב</strong>
          <strong> - </strong>
          <strong>מפעיל</strong>
          <strong> </strong>
          <strong>השירות</strong>
          <strong> </strong>
          <strong>אינו</strong>
          <strong> </strong>
          <strong>מציע</strong>
          <strong> </strong>
          <strong>ואינו</strong>
          <strong> </strong>
          <strong>מספק</strong>
          <strong> </strong>
          <strong>את</strong>
          <strong> </strong>
          <strong>המוצרים</strong>
          <strong> </strong>
          <strong>שמפרסמים</strong>
          <strong> </strong>
          <strong>בתי</strong>
          <strong> </strong>
          <strong>העסק</strong>
          <strong>. </strong>
          <strong>השירות</strong>
          <strong> </strong>
          <strong>משמש</strong>
          <strong> </strong>
          <strong>כזירה</strong>
          <strong> </strong>
          <strong>מקוונת</strong>
          <strong> </strong>
          <strong>בלבד</strong>
          <strong> </strong>
          <strong>המאפשרת</strong>
          <strong> </strong>
          <strong>לבתי</strong>
          <strong> </strong>
          <strong>העסק</strong>
          <strong> </strong>
          <strong>להציג</strong>
          <strong> </strong>
          <strong>את</strong>
          <strong> </strong>
          <strong>מוצריהם</strong>
          <strong>, </strong>
          <strong>על</strong>
          <strong>-</strong>
          <strong>מנת</strong>
          <strong> </strong>
          <strong>לאפשר</strong>
          <strong> </strong>
          <strong>לך</strong>
          <strong> </strong>
          <strong>להזמינם</strong>
          <strong> </strong>
          <strong>באמצעות</strong>
          <strong> </strong>
          <strong>שליחי</strong>
          <strong> PIKA, </strong>
          <strong>או</strong>
          <strong> </strong>
          <strong>באיסוף</strong>
          <strong> </strong>
          <strong>ישיר</strong>
          <strong> </strong>
          <strong>מבית</strong>
          <strong> </strong>
          <strong>העסק</strong>
          <strong>. </strong>
          <strong>לפיכך</strong>
          <strong>, </strong>
          <strong>כל</strong>
          <strong> </strong>
          <strong>האחריות</strong>
          <strong> </strong>
          <strong>לפרסומי</strong>
          <strong> </strong>
          <strong>בתי</strong>
          <strong> </strong>
          <strong>העסק</strong>
          <strong> </strong>
          <strong>ולמוצרים</strong>
          <strong> </strong>
          <strong>שהם</strong>
          <strong> </strong>
          <strong>מציעים</strong>
          <strong>, </strong>
          <strong>לרבות</strong>
          <strong> </strong>
          <strong>תכונותיהם</strong>
          <strong>, </strong>
          <strong>הכנתם</strong>
          <strong>, </strong>
          <strong>טיבם</strong>
          <strong>, </strong>
          <strong>אריזתם</strong>
          <strong> </strong>
          <strong>ואספקתם</strong>
          <strong>, </strong>
          <strong>כשרות</strong>
          <strong>, </strong>
          <strong>אלרגנים</strong>
          <strong>, </strong>
          <strong>צמחונות</strong>
          <strong>/</strong>
          <strong>טבעונות</strong>
          <strong>, </strong>
          <strong>חריפות</strong>
          <strong>, </strong>
          <strong>חשיפה</strong>
          <strong> </strong>
          <strong>לגלוטן</strong>
          <strong> </strong>
          <strong>וכו</strong>
          <strong>', </strong>
          <strong>חלה</strong>
          <strong> </strong>
          <strong>על</strong>
          <strong> </strong>
          <strong>בתי</strong>
          <strong> </strong>
          <strong>העסק</strong>
          <strong> </strong>
          <strong>ועליהם</strong>
          <strong> </strong>
          <strong>בלבד</strong>
          <strong>. </strong>
          <strong>מפעיל</strong>
          <strong> </strong>
          <strong>השירות</strong>
          <strong> </strong>
          <strong>אינו</strong>
          <strong> </strong>
          <strong>מפקח</strong>
          <strong> </strong>
          <strong>על</strong>
          <strong> </strong>
          <strong>המידע</strong>
          <strong> </strong>
          <strong>שמציגים</strong>
          <strong> </strong>
          <strong>בתי</strong>
          <strong> </strong>
          <strong>העסק</strong>
          <strong> </strong>
          <strong>ועל</strong>
          <strong> </strong>
          <strong>נכונותו</strong>
          <strong>, </strong>
          <strong>או</strong>
          <strong> </strong>
          <strong>על</strong>
          <strong> </strong>
          <strong>טיב</strong>
          <strong> </strong>
          <strong>ותכונות</strong>
          <strong> </strong>
          <strong>המוצרים</strong>
          <strong> </strong>
          <strong>הנרכשים</strong>
          <strong>. </strong>
          <strong>האחריות</strong>
          <strong> </strong>
          <strong>למידע</strong>
          <strong>, </strong>
          <strong>טיבו</strong>
          <strong>, </strong>
          <strong>אמינותו</strong>
          <strong> </strong>
          <strong>ולמוצרי</strong>
          <strong> </strong>
          <strong>בתי</strong>
          <strong> </strong>
          <strong>העסק</strong>
          <strong>, </strong>
          <strong>מוטלת</strong>
          <strong> </strong>
          <strong>כולה</strong>
          <strong> </strong>
          <strong>על</strong>
          <strong> </strong>
          <strong>בתי</strong>
          <strong> </strong>
          <strong>העסק</strong>
          <strong> </strong>
          <strong>עצמם</strong>
          <strong>. </strong>
        </p>
        <p dir="rtl">
          למפעיל השירות שיקול הדעת הבלעדי להחליט אילו בתי עסק יוצגו בשירות, משך הזמן בהם יוצגו, מה
          יהיה מיקומם ועיצובם של עמודי בתי העסק, סדר תוצאות החיפוש, וכל עניין אחר הקשור בהם ובכלל
          זאת להסיר כל תוכן או מידע מהשירות לפי שיקול דעתו הבלעדי. מידע נוסף על אודות השירות,
          תכונותיו ואפשרויות השימוש בו, תוכל למצוא בעמודי השירות עצמו. אין בפרסום מוצרים מטעם בית
          עסק משום המלצה של מפעיל השירות לרכישת המוצרים ועלייך להפעיל שיקול דעת עצמאי באשר לבתי העסק
          שברצונך להזמין מהם מוצרים באמצעות השירות.
        </p>
        <p dir="rtl">
          בתי העסק המציגים בשירות רשאים לשנות בכל עת את היצע ומגוון המוצרים שהם מציעים בשירות.
          העובדה שמוצר מסוים הוצע למכירה במועד כלשהו אינה מבטיחה שיוצע למכירה גם בעתיד. מפעיל השירות
          אינו מתחייבת להיקף, זמינות או מגוון מינימלי של מוצרים כלשהם, או של מספר בתי העסק המציעים
          ממרכולתם בשירות. תמונות המוצרים בשירות מוצגות לצורכי המחשה בלבד, על-ידי ומטעם בתי העסק.
          ייתכנו הבדלים במראה, בגוון, בגודל, וכיו"ב בין המוצר, כפי שהוא מוצג בעמוד בית העסק, לבין
          המוצר במציאות.
        </p>
        <p dir="rtl">
          במידה ואתה סבור כי הפירוט והמידע על בתי העסק, או אילו מהמוצרים שהם מציעים, אינו מספק, או
          אם אתה סבור כי הוא מטעה, לא מדויק, או דורש התייחסות נוספת מכל סיבה אחרת – אל תהסס לפנות
          אלינו בהתאם לפרטי יצירת הקשר המפורטים בתחתית תנאים אלה.
        </p>
        <p dir="rtl">
          <br />
          <strong>לתשומת</strong>
          <strong> </strong>
          <strong>ליבך</strong>
          <strong>: </strong>
          <strong>חלק</strong>
          <strong> </strong>
          <strong>מהותי</strong>
          <strong> </strong>
          <strong>בשירות</strong>
          <strong> </strong>
          <strong>וביישומון</strong>
          <strong> </strong>
          <strong>הוא</strong>
          <strong> </strong>
          <strong>שירותי</strong>
          <strong> </strong>
          <strong>איתור</strong>
          <strong> </strong>
          <strong>מבוססי</strong>
          <strong> </strong>
          <strong>מיקום</strong>
          <strong>, </strong>
          <strong>הנעזרים</strong>
          <strong> </strong>
          <strong>בטכנולוגיית</strong>
          <strong> GPS </strong>
          <strong>על</strong>
          <strong> </strong>
          <strong>מנת</strong>
          <strong> </strong>
          <strong>לקבוע</strong>
          <strong> </strong>
          <strong>את</strong>
          <strong> </strong>
          <strong>מיקומך</strong>
          <strong> </strong>
          <strong>כמשתמש</strong>
          <strong> </strong>
          <strong>בשירות</strong>
          <strong>, </strong>
          <strong>באמצעות</strong>
          <strong> </strong>
          <strong>מכשיר</strong>
          <strong> </strong>
          <strong>הקצה</strong>
          <strong> </strong>
          <strong>שלך</strong>
          <strong>, </strong>
          <strong>לצורך</strong>
          <strong> </strong>
          <strong>השימוש</strong>
          <strong> </strong>
          <strong>במיקומך</strong>
          <strong> </strong>
          <strong>על</strong>
          <strong>-</strong>
          <strong>מנת</strong>
          <strong> </strong>
          <strong>לאתר</strong>
          <strong> </strong>
          <strong>את</strong>
          <strong> </strong>
          <strong>מקום</strong>
          <strong> </strong>
          <strong>ההזמנה</strong>
          <strong> </strong>
          <strong>ו</strong>
          <strong>/</strong>
          <strong>או</strong>
          <strong> </strong>
          <strong>מקום</strong>
          <strong> </strong>
          <strong>קבלת</strong>
          <strong> </strong>
          <strong>משלוח</strong>
          <strong> </strong>
          <strong>המוצרים</strong>
          <strong>, </strong>
          <strong>וכן</strong>
          <strong> </strong>
          <strong>על</strong>
          <strong>-</strong>
          <strong>מנת</strong>
          <strong> </strong>
          <strong>לנסות</strong>
          <strong> </strong>
          <strong>ולחשב</strong>
          <strong>, </strong>
          <strong>עד</strong>
          <strong> </strong>
          <strong>כמה</strong>
          <strong> </strong>
          <strong>שהדבר</strong>
          <strong> </strong>
          <strong>מתאפשר</strong>
          <strong>, </strong>
          <strong>את</strong>
          <strong> </strong>
          <strong>זמן</strong>
          <strong> </strong>
          <strong>ההגעה</strong>
          <strong> </strong>
          <strong>של</strong>
          <strong> </strong>
          <strong>שליחי</strong>
          <strong> PIKA </strong>
          <strong>בעת</strong>
          <strong> </strong>
          <strong>הזמנת</strong>
          <strong> </strong>
          <strong>משלוח</strong>
          <strong>, </strong>
          <strong>וכן</strong>
          <strong> </strong>
          <strong>על</strong>
          <strong>-</strong>
          <strong>מנת</strong>
          <strong> </strong>
          <strong>לאפשר</strong>
          <strong> </strong>
          <strong>לך</strong>
          <strong> </strong>
          <strong>לעקוב</strong>
          <strong> </strong>
          <strong>בזמן</strong>
          <strong> </strong>
          <strong>אמת</strong>
          <strong> </strong>
          <strong>אחר</strong>
          <strong> </strong>
          <strong>מסלול</strong>
          <strong> </strong>
          <strong>השליח</strong>
          <strong> </strong>
          <strong>עד</strong>
          <strong> </strong>
          <strong>להגעתו</strong>
          <strong> </strong>
          <strong>ליעדו</strong>
          <strong>.</strong>
        </p>
        <p dir="rtl">
          <br />
          <strong>רישום</strong>
          <strong> </strong>
          <strong>לשירות</strong>
        </p>
        <p dir="rtl">
          <br />
          <strong>הרישום</strong>
          <strong> </strong>
          <strong>לשירות</strong>
          <strong>, </strong>
          <strong>השימוש</strong>
          <strong> </strong>
          <strong>בו</strong>
          <strong> </strong>
          <strong>והזמנת</strong>
          <strong> </strong>
          <strong>מוצרים</strong>
          <strong> </strong>
          <strong>מבתי</strong>
          <strong> </strong>
          <strong>העסק</strong>
          <strong> </strong>
          <strong>באמצעותו</strong>
          <strong>, </strong>
          <strong>מותרים</strong>
          <strong> </strong>
          <strong>למשתמשים</strong>
          <strong> </strong>
          <strong>בגירים</strong>
          <strong>, </strong>
          <strong>בני</strong>
          <strong> 18 </strong>
          <strong>ומעלה</strong>
          <strong> </strong>
          <strong>בלבד</strong>
          <strong>, </strong>
          <strong>לכל</strong>
          <strong> </strong>
          <strong>תאגיד</strong>
          <strong> </strong>
          <strong>המאוגד</strong>
          <strong> </strong>
          <strong>כדין</strong>
          <strong> </strong>
          <strong>בישראל</strong>
          <strong>, </strong>
          <strong>או</strong>
          <strong> </strong>
          <strong>לכל</strong>
          <strong> </strong>
          <strong>אישיות</strong>
          <strong> </strong>
          <strong>משפטית</strong>
          <strong> </strong>
          <strong>כשירה</strong>
          <strong> </strong>
          <strong>אחרת</strong>
          <strong>. </strong>
          <strong>השימוש</strong>
          <strong> </strong>
          <strong>בשירות</strong>
          <strong> </strong>
          <strong>אינו</strong>
          <strong> </strong>
          <strong>מותר</strong>
          <strong> </strong>
          <strong>לקטינים</strong>
          <strong> </strong>
          <strong>שגילם</strong>
          <strong> </strong>
          <strong>נמוך</strong>
          <strong> </strong>
          <strong>מ</strong>
          <strong>-18.</strong>
        </p>
        <p dir="rtl">
          השימוש בשירות והזמנת מוצרים מבתי עסק באמצעותו מחייבים רישום, מסירת המידע המתבקש במהלך
          הרישום/ההזמנה וקבלת הסכמתך לתנאי המסמכים המחייבים ובפרט תנאי שימוש אלה. לא ניתן להשתמש
          בשירות ללא השלמת הליך הרישום.
        </p>
        <p dir="rtl">
          בעת שתבקש להירשם לשירות יהיה עליך תחילה להזין את מספר הטלפון הנייד שלך, ולצורך אימות
          וזיהוי נבקש ממך להזין את קוד האימות אותו נשלח למספר הטלפון שלך באמצעות מסרון (SMS). לאחר
          השלמת הליך האימות, תתבקש להזין פרטים אישיים ופרטי יצירת קשר איתך, כגון, שמך המלא, כתובת
          דוא"ל פעילה, כתובת למשלוחים ועוד. מפעיל השירות רשאי לקבוע ולהוסיף מעת לעת דרכי רישום אחרות
          ונוספות, לרבות דרכי אימות וזיהוי שונות.<strong> </strong>
        </p>
        <p dir="rtl">
          במידה ותרצה לפנות למפעיל השירות באמצעות טופס יצירת הקשר [Pikadelivery.com/contact] בגוף
          השירות, תידרש למסור לנו את פרטי הקשר הנדרשים בשדות החובה של הטופס וכן להזין את תוכן פנייתך
          במקום המיועד לכך.
        </p>
        <p dir="rtl">
          <br />
          <strong>עליך</strong>
          <strong> </strong>
          <strong>למסור</strong>
          <strong> </strong>
          <strong>תמיד</strong>
          <strong> </strong>
          <strong>רק</strong>
          <strong> </strong>
          <strong>פרטים</strong>
          <strong> </strong>
          <strong>נכונים</strong>
          <strong>, </strong>
          <strong>מדויקים</strong>
          <strong> </strong>
          <strong>ומלאים</strong>
          <strong> </strong>
          <strong>ואתה</strong>
          <strong> </strong>
          <strong>מאשר</strong>
          <strong> </strong>
          <strong>בזאת</strong>
          <strong> </strong>
          <strong>את</strong>
          <strong> </strong>
          <strong>נכונות</strong>
          <strong> </strong>
          <strong>הפרטים</strong>
          <strong> </strong>
          <strong>שמסרת</strong>
          <strong>. </strong>
          <strong>מסירת</strong>
          <strong> </strong>
          <strong>פרטים</strong>
          <strong> </strong>
          <strong>שגויים</strong>
          <strong> </strong>
          <strong>עלולה</strong>
          <strong> </strong>
          <strong>למנוע</strong>
          <strong> </strong>
          <strong>ממך</strong>
          <strong> </strong>
          <strong>את</strong>
          <strong> </strong>
          <strong>האפשרות</strong>
          <strong> </strong>
          <strong>להשתמש</strong>
          <strong> </strong>
          <strong>בשירות</strong>
          <strong>, </strong>
          <strong>לקבל</strong>
          <strong> </strong>
          <strong>את</strong>
          <strong> </strong>
          <strong>המוצרים</strong>
          <strong> </strong>
          <strong>שהזמנת</strong>
          <strong> </strong>
          <strong>באמצעות</strong>
          <strong> </strong>
          <strong>השירות</strong>
          <strong> </strong>
          <strong>ובמקרה</strong>
          <strong> </strong>
          <strong>הצורך</strong>
          <strong> </strong>
          <strong>לסכל</strong>
          <strong> </strong>
          <strong>יצירת</strong>
          <strong> </strong>
          <strong>קשר</strong>
          <strong> </strong>
          <strong>אתך</strong>
          <strong> </strong>
          <strong>כשהדבר</strong>
          <strong> </strong>
          <strong>דרוש</strong>
          <strong>. </strong>
          <strong>אנו</strong>
          <strong> </strong>
          <strong>נציין</strong>
          <strong> </strong>
          <strong>במפורש</strong>
          <strong> </strong>
          <strong>אילו</strong>
          <strong> </strong>
          <strong>שדות</strong>
          <strong> </strong>
          <strong>מידע</strong>
          <strong> </strong>
          <strong>מחייבים</strong>
          <strong> </strong>
          <strong>השלמה</strong>
          <strong>. </strong>
          <strong>ללא</strong>
          <strong> </strong>
          <strong>מסירת</strong>
          <strong> </strong>
          <strong>המידע</strong>
          <strong> </strong>
          <strong>המתבקש</strong>
          <strong> </strong>
          <strong>בשדות</strong>
          <strong> </strong>
          <strong>החובה</strong>
          <strong> </strong>
          <strong>הללו</strong>
          <strong>, </strong>
          <strong>לא</strong>
          <strong> </strong>
          <strong>תוכל</strong>
          <strong> </strong>
          <strong>להשלים</strong>
          <strong> </strong>
          <strong>את</strong>
          <strong> </strong>
          <strong>הרישום</strong>
          <strong> </strong>
          <strong>לשירות</strong>
          <strong> </strong>
          <strong>או</strong>
          <strong> </strong>
          <strong>לבצע</strong>
          <strong> </strong>
          <strong>את</strong>
          <strong> </strong>
          <strong>ההזמנה</strong>
          <strong> </strong>
          <strong>הרלבנטית</strong>
          <strong>. </strong>
          <strong>מפעיל</strong>
          <strong> </strong>
          <strong>השירות</strong>
          <strong> </strong>
          <strong>שומר</strong>
          <strong> </strong>
          <strong>על</strong>
          <strong> </strong>
          <strong>זכותו</strong>
          <strong> </strong>
          <strong>לנקוט</strong>
          <strong> </strong>
          <strong>באמצעים</strong>
          <strong> </strong>
          <strong>שונים</strong>
          <strong> </strong>
          <strong>ולבקש</strong>
          <strong> </strong>
          <strong>ממך</strong>
          <strong> </strong>
          <strong>פרטים</strong>
          <strong> </strong>
          <strong>נוספים</strong>
          <strong> </strong>
          <strong>כדי</strong>
          <strong> </strong>
          <strong>לאמת</strong>
          <strong> </strong>
          <strong>את</strong>
          <strong> </strong>
          <strong>זהותך</strong>
          <strong> </strong>
          <strong>ולוודא</strong>
          <strong> </strong>
          <strong>אותה</strong>
          <strong>.</strong>
        </p>
        <p dir="rtl">
          הפרטים שתמסור בעת הרישום וביצוע כל הזמנה יישמרו במאגר המידע של מפעיל השירות והוא לא יעשה
          בפרטיך שימוש אלא בהתאם ל<strong>מדיניות</strong> <strong> </strong>
          <strong>הפרטיות</strong> [Pikadelivery.com/privacy] של השירות, המהווה חלק בלתי נפרד
          מהמסמכים המחייבים.
        </p>
        <p dir="rtl">
          אתה עשוי להידרש מעת לעת להזין בגוף היישומון את פרטי הרישום שמסרת למפעיל השירות, וזאת לצורך
          התחברות מחודשת לשירות במקרה של יציאה/ניתוק ממנו. כדי למנוע שימוש לרעה בחשבונך בשירות עליך
          לשמור בסודיות גמורה על פרטי גישה אלה. אתה נושא באחריות המלאה לכל שימוש שייעשה בחשבונך
          בשירות, לרבות עקב מחדלך לשמור בסודיות את פרטי הגישה לחשבונך בשירות.
        </p>
        <p dir="rtl">
          <br />
          <strong>ביטול</strong>
          <strong> </strong>
          <strong>רישומך</strong>
          <strong> </strong>
          <strong>לשירות</strong>
        </p>
        <p dir="rtl">
          מפעיל השירות רשאי למנוע ממך להשתמש בשירות לפי שיקול דעתו המוחלט, גם אם הזמנת באמצעותו
          מוצרים בעבר. מפעיל השירות רשאי לבטל את רישומך לשירות, או לחסום את גישתך אליו, בין היתר, אך
          לא רק, בכל אחד מהמקרים הבאים -
        </p>
        <p dir="rtl">
          אם מסרת פרטים שגויים או בדויים בעת הרישום לשירות, או בכל חלק מהליך הזמנת המוצרים מבתי העסק
          באמצעותו;
        </p>
        <p dir="rtl">
          אם ביצעת מעשה או מחדל הפוגעים או עלולים לפגוע במפעיל השירות או באחרים, לרבות משתמשים
          אחרים, שליחים, בתי עסק, או שותפים/עובדים/קבלני משנה של מפעיל השירות;
        </p>
        <p dir="rtl">
          אם השתמשת בשירות לבצע או כדי לנסות לבצע מעשה בלתי חוקי או פוגעני, או כדי לאפשר, להקל,
          לסייע או לעודד את ביצועו של מעשה כזה;
        </p>
        <p dir="rtl">אם הפרת את אילו מתנאי המסמכים המחייבים;</p>
        <p dir="rtl">אם תעשה כל פעולה שתמנע מאחרים להירשם לשירות או להשתמש בו בכל דרך שהיא;</p>
        <p dir="rtl">אם מסרת את פרטי הרישום לשירות לשימושו של צד שלישי;</p>
        <p dir="rtl">
          אם יש לך חוב כספי למפעיל השירות, או למי מבתי העסק, ולא פרעת את חובך למרות שחלף המועד שנקבע
          לתשלומו;
        </p>
        <p dir="rtl">
          אם כרטיס האשראי או כל אמצעי תשלום אחר שברשותך נחסם או הוגבל לשימוש בדרך כלשהי;
        </p>
        <p dir="rtl">עם הפסקת פעילות מפעיל השירות או סיום פעילותו של השירות;</p>
        <p dir="rtl">לפי צו שיפוטי או הוראה של רשות מוסמכת;</p>
        <p dir="rtl">בכל מקרה אחר בו מפעיל השירות סבור כי הביטול מוצדק.</p>
        <p dir="rtl">
          מפעיל השירות עשוי להפעיל אמצעים שונים לניטור הפעילות בשירות ועל-מנת לאתר מקרי הונאה או
          שימוש לרעה בו. מפעיל השירות שומר על זכותו לחסום או לבטל את גישתך או רישומך לשירות, לפי
          שיקול דעתו הבלעדי, במידה וסבר כי חשבונך בשירות שימש לביצוע מעשים כאמור.
        </p>
        <p dir="rtl">
          <br />
          <strong>דיוור</strong>
          <strong> </strong>
          <strong>ישיר</strong>
          <strong> </strong>
          <strong>ומשלוח</strong>
          <strong> </strong>
          <strong>מידע</strong>
          <strong> </strong>
          <strong>ותוכן</strong>
          <strong> </strong>
          <strong>פרסומי</strong>
        </p>
        <p dir="rtl">
          לאחר שמסרת את פרטיך למפעיל השירות (באמצעות מנגנוני וטפסי יצירת הקשר, בעת רישום לשירות, בעת
          רכישת מוצרים באמצעותו, או בכל דרך אחרת), מפעיל השירות יהיה רשאי לשלוח אליך עדכונים, בדואר
          אלקטרוני, במסרונים (SMS), או בכל דרך אחרת ובכלל זה עדכוני תוכן, עלוני מידע, סקרים, מידע על
          שירותים ומוצרים (של מפעיל השירות ושל אחרים, לרבות בתי העסק), מבצעים, חידושים בשירות ודברי
          פרסומת, בין היתר כמשמעות הדברים בסעיף 30א' לחוק התקשורת (בזק ושידורים), התשמ"ב–1982, וכן
          בדיוור ישיר. הרישום לשירות ואישורך את המסמכים המחייבים מהווה את הסכמתך המפורשת לכך.
        </p>
        <p dir="rtl">
          בכל עת, תוכל לחזור בך מהסכמתך ולהודיע למפעיל השירות על סירובך לקבל דברי פרסומת, דרך כלל או
          מסוג מסוים, על-ידי משלוח הודעת סירוב. הדרך למשלוח הודעת הסירוב תפורט בדברי הפרסומת שישלח
          אלייך מפעיל השירות או מי מטעמו. תשומת ליבך כי גם במידה ותודיע על סירוב לקבל דברי פרסומת,
          מפעיל השירות יוכל להמשיך ולפנות אליך בהודעות בעלות אופי תפעולי, הנוגעות לפעילותך בשירות
          (כגון משלוח חשבוניות, סקרי שביעות רצון, הודעות על תחזוקה מתוכננת, וכיו"ב).
        </p>
        <p dir="rtl">
          בעת השימוש בשירות ייתכן ונבקש ממך לאשר קבלת הודעות מתפרצות (Push Notifications), ככל
          ומכשיר הקצה שלך תומך בכך. אם תאשר בקשה זו, מפעיל השירות עשוי לשלוח אליך הודעות כאמור
          באמצעות ממשק היישומון או מכשיר הקצה שלך. משלוח הודעות אלה היא תכונה מובנית בחלק ממכשירי
          הקצה. אין לרוב באפשרות מפעיל השירות לכבות ו/או לנתק תכונה זו במכשירך. במידה ותבחר לחדול
          מקבלת הודעות אלה יהיה עליך להסיר את תכונה זו במישרין בגוף היישומון, על-ידי שימוש בתפריט
          ההגדרות הרלבנטי במכשיר הקצה שלך.
        </p>
        <p dir="rtl">
          <br />
          <strong>הזמנת</strong>
          <strong> </strong>
          <strong>מוצרים</strong>
          <strong> </strong>
          <strong>מבתי</strong>
          <strong> </strong>
          <strong>עסק</strong>
          <strong> </strong>
          <strong>באמצעות</strong>
          <strong> </strong>
          <strong>השירות</strong>
        </p>
        <p dir="rtl">
          <br />
          <strong>כללי</strong>- על-מנת להזמין מוצרים מבתי עסק באמצעות השירות, יהיה עלייך להיכנס
          לעמוד בית העסק הרלבנטי, לבחור את המוצרים שברצונך להזמין (בהתאם למידע, לפירוט ולמחירים
          המוצגים על-ידי בית העסק שממנו אתה מעוניין לבצע את הזמנתך), ולאשר את הזמנתך.
        </p>
        <p dir="rtl">
          <br />
          <strong>סל</strong>
          <strong> </strong>
          <strong>הקניות</strong>- מוצרים שאתה מעונין להזמין מבית העסק הרלבנטי יישמרו ב"סל הקניות"
          עד להשלמת תהליך ההזמנה. מפעיל השירות רשאי להגדיר סכום מינימום לרכישת מוצרים באמצעות השירות
          וכן לקבוע מכסות מקסימליות לרכישת מוצרים, בכלל או מסוג מסוים.
        </p>
        <p dir="rtl">
          <br />
          <strong>הליך</strong>
          <strong> </strong>
          <strong>ההזמנה</strong>- לאחר שהוספת מוצרים לסל הקניות, יהיה עליך להזין בשדות המיועדים לכך
          בתהליך ההזמנה את המידע והפרטים האישיים הנדרשים. פרטים אלה עשויים לכלול את שמך המלא, שיטת
          המשלוח (ובמקרה של איסוף עצמי – בחירת הסניף לאיסוף), כתובת מדויקת למשלוח, כתובת דוא"ל
          פעילה, טלפון, פרטי אמצעי תשלום וכל פרט נוסף שאנו עשויים לדרוש במהלך ההזמנה, כגון מידע
          בנוגע לפעולה אותה מתבקש השליח לבצע במידה והנמען אינו בבית. לפי שיקול דעתנו, אנו עשויים
          לדרוש ממך מסירת פרטים כאמור הן ביחס למבצע הרכישה והן ביחס לפרטי האדם / הגוף אליו יישלחו
          המוצרים שהזמנת. תוכל גם להוסיף הערות ובקשות מיוחדות בגוף היישומון.
        </p>
        <p dir="rtl">
          אנו נספק לך את המוצרים שהזמנת באמצעות השירות לאחר שהשלמת את תהליך הרכישה במלואו, ככל
          שהמוצר שהזמנת מצוי במלאי, במידה ובבעלותך כרטיס אשראי תקף שניתן לחייבו ולסלוק בישראל, ולאחר
          שחברת כרטיסי האשראי שהנפיקה את הכרטיס אישרו את עסקת הרכישה שביצעת.
        </p>
        <p dir="rtl">
          אם חברת כרטיסי האשראי לא אישרו את חיובך, לא נהיה חייבים לספק לך את המוצרים שהזמנת. במקרה
          זה ניצור איתך קשר ונמנע מחיוב כרטיס האשראי שלך. אם חויבת בטעות, הינך מתבקש להודיע לנו ואנו
          נדאג לזיכוי מתאים, בהתאם לנסיבות ולהוראות הדין.
        </p>
        <p dir="rtl">
          <br />
          <strong>הזמנה</strong>
          <strong> </strong>
          <strong>משובשת</strong>- כדי שנוכל לספק לך את המוצרים שהזמנת מבית העסק, הזמנתך צריכה
          להתקבל ולהיקלט אצלנו באופן תקין ומסודר, כשהיא מכילה את כל הפרטים הנדרשים ליצירת קשר איתך
          ולמשלוח המוצרים. מפעיל השירות לא יספק את המוצרים שהזמנת אם ההזמנה לא נקלטה במערכותיו, או
          אם נקלטה באופן משובש, וזאת גם אם מקור התקלה הוא במערכות מפעיל השירות. אם לא תקבל פניה
          ממפעיל השירות תוך זמן סביר ממועד ביצוע ההזמנה, באמצעות הטלפון או בדוא"ל, יש להניח כי חלה
          תקלה בהזמנה. במקרה זה מומלץ שתפנה אל מפעיל השירות כמפורט בתנאים אלה. אנו נשתדל לברר ככל
          הניתן את מקור התקלה ולסייע לך בביצוע ההזמנה, או ביטולה ככל ותבקש זאת.
        </p>
        <p dir="rtl">
          <br />
          <strong>אזורי</strong>
          <strong> </strong>
          <strong>ומועד</strong>
          <strong> </strong>
          <strong>המשלוח</strong>
        </p>
        <p dir="rtl">
          האפשרות להזמין מוצרים באמצעות השירות מוגבלת בעת הזו לאזור העיר נצרת וסביבתה ("
          <strong>אזורי</strong>
          <strong> </strong>
          <strong>החלוקה</strong>"). אנו רשאים, אך לא חייבים, לספק את השירות גם אל מחוץ לאזורי
          החלוקה, בתיאום טלפוני מראש. לכן גם אם הזמנה כזו התקבלה ונקלטה במערכות השירות, לא נהיה
          חייבים לספקה. ככל ומשלוח לכתובת המבוקשת כרוך בעלות נוספת מסיבה כלשהי, הדבר יצוין במפורש
          ביישומון.
        </p>
        <p dir="rtl">
          מפעיל השירות יפעל כמיטב יכולתו כדי לספק את המוצרים שהזמנת מבית העסק במהירות המרבית, אך
          איננו יכולים להתחייב כי נעמוד בזמן שהוצג. זמן ההגעה המשוער למשלוח וכן מסלול השליח יוצג לך
          בשירות עצמו. במידה ואין ביכולתנו לבצע את המשלוח עד לכתובת למשלוח מכל סיבה שהיא, אנו נודיע
          לך על כך ונפעל למציאת פתרון חלופי המקובל על שני הצדדים.
        </p>
        <p dir="rtl">
          איננו נושאים באחריות לעיכובים שיחולו במסירת המוצרים, אף אם זמן המסירה והשליחות ארוך יותר
          מזמן ההגעה המשוער שהוצג לכך. בכל מקרה, מפעיל השירות לא יהיה אחראי לעיכובים באספקת המוצרים
          כתוצאה מאירועים שאינם בשליטתו, כדוגמת תקלות, עיכובים, שביתות, פקקים, חסימות, תקלות במערכת
          המחשוב שיפגעו בהשלמת תהליך הרכישה, סיבות של כוח עליון, או כל גורם סביר אחר לעיכוב כאמור.
        </p>
        <p dir="rtl">
          <br />
          <strong>מחירים</strong>
          <strong> </strong>
          <strong>ודמי</strong>
          <strong> </strong>
          <strong>משלוח</strong>
        </p>
        <p dir="rtl">
          כל המחירים בשירות מפורטים בעמודי בתי העסק ונקובים בשקלים חדשים. המחירים כוללים מע"מ, אם
          הוא חל לפי הדין, ואינם כוללים דמי משלוח (אלו יצוינו במפורש בעת ביצוע ההזמנה, בהתאם לאזור
          החלוקה הרלבנטי, למעט אם בחרת באפשרות של איסוף עצמי).
        </p>
        <p dir="rtl">
          בתי העסק רשאים לעדכן את מחירי המוצרים מעת לעת ובלא צורך בהודעה מוקדמת. המחיר התקף ביחס
          להזמנה שביצעת הוא המחיר שהתפרסם בעת שהשלמת את תהליך ההזמנה (הכולל את מסירת פרטי אמצעי
          תשלום). אם עודכנו המחירים לפני שהושלם תהליך ההזמנה, תחויב לפי המחירים המעודכנים.
        </p>
        <p dir="rtl">
          לתשומת לב: למרות שבתי העסק מקפידים לבדוק את המחירים המופיעים בשירות, לעתים עלולה לחול טעות
          שבעקבותיה יוצג בשירות מחיר שגוי. במקרה זה נפנה אליך לאחר שביצעת את הזמנתך, נודיע לך מהו
          המחיר הנכון של המוצר ונאפשר לך לאשר כי אתה מבקש לרוכשו במחיר הנכון. אם תסרב לרכוש את המוצר
          במחירו הנכון - לא נהיה חייבים לספקו לך ובמקרה זה לא תהיינה לך טענות כלשהן כלפי מפעיל
          השירות.
        </p>
        <p dir="rtl">
          <br />
          <strong>דמי</strong>
          <strong> </strong>
          <strong>משלוח</strong>
          <strong> </strong>-<strong> </strong>בנוסף למחיר המוצרים שהזמנת, עליך לשלם למפעיל השירות
          דמי משלוח. סכום דמי המשלוח יופיע בתום תהליך ההזמנה בסמוך לפירוט המוצרים שבחרת לרכוש
          ומחירם. התעריפים המלאים של דמי המשלוח מחושבים בשירות בהתאם לאזורי החלוקה. ככל ומשלוח
          לכתובת המבוקשת כרוך בעלות נוספת, הדבר יצוין במפורש בעת ההזמנה. לחילופין, תוכל לאסוף את
          המוצרים שהזמנת מהסניף אותו בחרת במהלך הליך ההזמנה, ללא עלות. מפעיל השירות רשאי לשנות את
          דמי והמשלוח מעת לעת. התשלום התקף ביחס להזמנה שביצעת הוא המחיר שהתפרסם בעת שהשלמת את תהליך
          ההזמנה על-ידי מסירת פרטי אמצעי התשלום.
        </p>
        <p dir="rtl">
          <br />
          <strong>תשלום</strong>
          <strong> </strong>
          <strong>ותנאי</strong>
          <strong> </strong>
          <strong>תשלום</strong>
        </p>
        <p dir="rtl">
          התשלום עבור המוצרים שתרכוש מבתי העסק יתבצע באמצעות כרטיס אשראי. עליך למסור את מספר כרטיס
          האשראי, תעודת הזהות של בעל הכרטיס, סוג הכרטיס, תוקפו וכל פרט נוסף הדרוש לנו לצורך השלמת
          הליך ההזמנה (כגון הספרות שבגב הכרטיס). מפעיל השירות רשאי להפסיק את השימוש באמצעי תשלום
          כלשהו בשירות, להתיר שימוש באמצעי תשלום נוספים ולהחיל הסדרי תשלום שונים על סוגי כרטיסי
          האשראי או אמצעי התשלום שמפעיל השירות יכבד. אין להשתמש באמצעי תשלום שאינו שייך לך וללא
          הרשאות הנדרשות.
        </p>
        <p dir="rtl">
          לאחר שהזנת את פרטי אמצעי התשלום ועם סיום הליך ההזמנה, יישלח אליך אישור באמצעות הדוא"ל על
          קליטת פרטי הזמנתך. אישור זה מעיד שפרטי הזמנתך נקלטו אצל מפעיל השירות. אם יתברר שאמצעי
          התשלום שמסרת אינו תקף, או שחברת כרטיסי האשראי אינה מכבדת את העסקה, או שהמוצר המבוקש איננו
          מצוי במלאי, מפעיל השירות ייצור איתך קשר לשם השלמת העסקה או ביטולה. מפעיל השירות סולק את
          העסקאות בשירות באמצעות שירותי חברת PayMe. פרטי אמצעי התשלום שתמסור לצורך ביצוע ההזמנה אינם
          נשמרים בידי מפעיל השירות.
        </p>
        <p dir="rtl">
          התשלום עשוי להיות כרוך בעמלות שונות שייגבו ממך על-ידי מפעילי שירות הסליקה או התשלום בהם
          תשתמש ובהתאם לתנאי השירות של אותם ספקים חיצוניים. אתה נושא באחריות הבלעדית לשאת בעמלות
          אלה, ככל הנדרש. אם השימוש באמצעי התשלום אושר על-ידי חברת כרטיסי האשראי שהנפיקה אותו, תימסר
          לך הודעה מתאימה על-כך וחשבונך יחויב בעלות המוצרים שהזמנת ודמי המשלוח (ככל וחלים). מועדי
          המשלוח יחושבו החל ממועד קבלת אישור העסקה.
        </p>
        <p dir="rtl">
          <br />
          <strong>מבצעים</strong>
          <strong>, </strong>
          <strong>הטבות</strong>
          <strong> </strong>
          <strong>והנחות</strong>
          <strong> </strong>
          <strong>בשירות</strong>
        </p>
        <p dir="rtl">
          מפעיל השירות רשאי להציע בשירות מבצעים, הטבות, הנחות וקופונים מסוגים שונים (בהתאם לתנאיו של
          כל קופון כפי שיתפרסמו בשירות או על גבי הקופון עצמו). בכפוף להוראות כל דין שעניינו במבצעים,
          מפעיל השירות רשאי בכל עת להפסיק מבצעים, הטבות והנחות אלה, או לחדול מהשימוש בקופונים,
          להחליפם או לשנותם, בלא צורך לתת כל הודעה מוקדמת על כך. אין באמור כדי לגרוע מזכותך להשתמש
          בקופונים שטרם פקע התוקף שנקבע להם. מבצעים, הטבות והנחות שניתנים באופן עצמאי על-ידי אילו
          מבתי העסק המציגים בשירות, הם באחריותם המלאה של אותם בתי עסק.
        </p>
        <p dir="rtl">
          אין לך זכות קנויה ליהנות ממבצעים, הטבות והנחות בשירות, אלא ככל והוקנתה לך זכות זו במפורש.
          כל שינוי בפרטי הזמנתך או במחירה, תהיה סיבתו אשר תהיה, יגרור אחריו בדיקה מחודשת של זכאותך
          למבצע, הטבה או הנחה. כך, לדוגמה, אם רכשת מוצר והיית זכאי לקבלת הטבה בגין רכישתו, אולם מוצר
          זה לא נמצא במלאי, לא תהיה זכאי לאותה הטבה; אם החלפת מוצר, שרכישתו לוותה במבצע, במוצר חלופי
          שאינו מזכה במבצע, לא תהיה זכאי להטבה שנלוותה לרכישת המוצר המקורי; אם סכום הזמנתך פחת בשל
          העובדה שמספר המוצרים שסופקו לך שונה מכפי שהזמנת, יכול שתישלל זכאותך למבצע, הנחה או הטבה
          שהיו מוקנים לך אלמלא פחת מחיר ההזמנה.
        </p>
        <p dir="rtl">
          דוגמאות אלה אינן ממצות את השינויים האפשריים בזכאותך למבצעים, הטבות או הנחות בשירות. יחד עם
          זאת, מפעיל השירות ישתדל להודיע לך טלפונית או בדוא"ל ולקבל את הנחיותיך במקרה שזכאותך למבצע,
          הטבה או הנחה תישלל או תשתנה עקב שינוי בפרטי הזמנתך.
        </p>
        <p dir="rtl">
          <br />
          <strong>מכירת</strong>
          <strong> </strong>
          <strong>יין</strong>
          <strong> </strong>
          <strong>ואלכוהול</strong>
          <strong> </strong>
          <strong>באמצעות</strong>
          <strong> </strong>
          <strong>השירות</strong>
        </p>
        <p dir="rtl">
          כמפורט בתנאים אלה, הרישום לשירות והשימוש בו אינם מותרים לקטינים שגילם נמוך מ-18. הואיל
          והמוצרים שמציעים בתי העסק עשויים לכלול גם יין ואלכוהול, הרי שחשוב להדגיש כי מכירת יין
          ואלכוהול לקטינים בני 18 ומטה היא אסורה לפי דין ומהווה עבירה פלילית.
        </p>
        <p dir="rtl">
          <br />
          <strong>אזהרה</strong>
          <strong>: </strong>
          <strong>חלק</strong>
          <strong> </strong>
          <strong>מהמוצרים</strong>
          <strong> </strong>
          <strong>מכילים</strong>
          <strong> </strong>
          <strong>אלכוהול</strong>
          <strong> – </strong>
          <strong>מומלץ</strong>
          <strong> </strong>
          <strong>להימנע</strong>
          <strong> </strong>
          <strong>משתייה</strong>
          <strong> </strong>
          <strong>מופרזת</strong>
          <strong>.</strong>
        </p>
        <p dir="rtl">
          <br />
          <strong>צריכה</strong>
          <strong> </strong>
          <strong>מופרזת</strong>
          <strong> </strong>
          <strong>של</strong>
          <strong> </strong>
          <strong>אלכוהול</strong>
          <strong> </strong>
          <strong>מסכנת</strong>
          <strong> </strong>
          <strong>חיים</strong>
          <strong> </strong>
          <strong>ומזיקה</strong>
          <strong> </strong>
          <strong>לבריאות</strong>
          <strong>!</strong>
        </p>
        <p dir="rtl">
          האיסור על מכירת יין ואלכוהול לקטינים יחול גם ביחס למקבלי השליחות של המוצרים שמציעים בתי
          העסק, ככל והמוצרים כוללים יינות או משקאות אלכוהוליים. מפעיל השירות רשאי לדרוש כי מקבל
          המשלוח (או מי שאוסף את המשלוח) יציג תעודה מזהה על-מנת לוודא כי הוא בגיר. שליחי השירות
          יסרבו למסור את המשלוח ככל שמקבל המשלוח הוא קטין, או מסרב להציג תעודה מזהה.
        </p>
        <p dir="rtl">
          במקרה כאמור יהיה רשאי מפעיל השירות לבטל את העסקה ולא תהיה לך כל טענה, דרישה או תביעה כלפיו
          בגין כך. לחילופין, רשאי מפעיל השירות, לפי שיקול דעתו, לאפשר לך למסור את המשלוח לנמען אחר
          (בגיר), בכפוף לתשלום עלויות המשלוח הנוספות, אותן נפרט בפנייך במקרה זה.
        </p>
        <p dir="rtl">
          <br />
          <strong>כללי</strong>
          <strong> </strong>
          <strong>שימוש</strong>
          <strong> </strong>
          <strong>נאות</strong>
          <strong> </strong>
          <strong>בשירות</strong>
        </p>
        <p dir="rtl">
          מבלי לגרוע מיתר הוראות המסמכים המחייבים, אתה רשאי להשתמש בשירות בהתאם לכללים המפורטים
          להלן. אין להשתמש בשירות באופן אחר, אלא אם כן קיבלת את הסכמתו המפורשת של מפעיל השירות לכך,
          מראש, בכתב ובכפוף לתנאי אותה הסכמה (ככל שתינתן) -
        </p>
        <p dir="rtl">
          אין להשתמש בשירות באופן המהווה או העלול להוות עבירה פלילית או עוולה אזרחית בהתאם להוראות
          כל דין, או הפוגע בזכויותיו של צד שלישי כלשהו;
        </p>
        <p dir="rtl">אתה רשאי להשתמש בשירות למטרות שאינן מסחריות בלבד;</p>
        <p dir="rtl">
          אין להעתיק ולהשתמש, או לאפשר לאחרים להעתיק ולהשתמש, בכל דרך אחרת בתכנים מתוך השירות, לרבות
          באתרים וביישומונים אחרים, בפרסומים אלקטרוניים, בפרסומי דפוס וכיו"ב, לכל מטרה אחרת;
        </p>
        <p dir="rtl">
          ככל ונרשמת לשירות כמפורט בתנאים אלה, אתה רשאי להשתמש בשירות באמצעות חשבון משתמש אחד בלבד;
        </p>
        <p dir="rtl">
          אין להפעיל אמצעים לעקיפה, נטרול או התגברות על מנגנוני האבטחה בשירות, ואין לפגוע או להפריע
          בדרך כלשהי לפעילותו התקינה של השירות;
        </p>
        <p dir="rtl">
          אין להפעיל כל יישום מחשב, או כל אמצעי אחר, לרבות תוכנות מסוג Crawlers, Robots וכדומה, לשם
          חיפוש, סריקה, העתקה או אחזור אוטומטי של תכנים מהשירות. בכלל זה, אין ליצור ואין להשתמש
          באמצעים כאמור לשם יצירת לקט, אוסף או מאגר מידע שיכילו תכנים או מידע מהשירות;
        </p>
        <p dir="rtl">אין להציג תכנים מהשירות בתוך מסגרת (Frame), גלויה או סמויה;</p>
        <p dir="rtl">
          אין להציג תכנים מהשירות בכל דרך שהיא, ובכלל זה באמצעות כל תוכנה, מכשיר, אביזר או פרוטוקול
          תקשורת המשנים את עיצובם או מחסירים מהם תכנים כלשהם ובפרט פרסומות ותכנים מסחריים;
        </p>
        <p dir="rtl">
          אין לקשר לשירות ולתכנים בו מכל מקור המכיל תכנים פורנוגראפיים, תכנים המעודדים לגזענות או
          להפליה פסולה, או המנוגדים לחוק, או המעודדים פעילות המנוגדת לחוק, או שפרסומם מנוגד לחוק;
        </p>
        <p dir="rtl">
          <br />
          <strong>קישורים</strong>
        </p>
        <p dir="rtl">
          תמצא בשירות קישורים (לינקים) לעמודים שונים באינטרנט ובכלל זה קישורים לאתרי אינטרנט של בתי
          עסק המציגים את מוצריהם בשירות. הקישורים מאפשרים לך למצוא תכנים המתפרסמים באינטרנט. תכנים
          אלה אינם מתפרסמים על ידי מפעיל השירות, או מטעמו ומפעיל השירות איננו שולט או מפקח עליהם.
          העובדה שהשירות מקשר לתכנים אלה אינה מעידה על הסכמת מפעיל השירות לתוכנם ואינה מהווה ערובה
          לאמינותם, לעדכניותם, לחוקיותם, לנוהגי בעלי התכנים בתחום הפרטיות ולכל היבט אחר הכרוך בהם.
          יתכן שתמצא שתכנים אלה אינם הולמים את צרכיך, או שאתה מתנגד לתוכנם, או סבור כי הם מקוממים,
          מרגיזים, בלתי נאותים, בלתי חוקיים או בלתי מוסריים.
        </p>
        <p dir="rtl">
          מפעיל השירות אינו אחראית לתכנים שאליהם מוליכים הקישורים ואינו אחראי לכל תוצאה שתיגרם
          מהשימוש בהם או מהסתמכות עליהם. מפעיל השירות איננו מתחייב כי הקישורים שימצאו בשירות יהיו
          תקינים ויובילו אותך לעמוד אינטרנט פעיל והוא רשאי להסיר מהשירות קישורים שנכללו בו בעבר
          ולהוסיף או להסיר קישורים חדשים, לפי שיקול דעתו המוחלט.
        </p>
        <p dir="rtl">
          <br />
          <strong>פרטיות</strong>
        </p>
        <p dir="rtl">
          מפעיל השירות מכבד את פרטיותך בעת השימוש בשירות. <strong>מדיניות</strong>
          <strong> </strong>
          <strong>הפרטיות</strong> [ <strong>Pikadelivery.com/privacy</strong>]<strong> </strong>של
          השירות מהווה חלק בלתי נפרד מהמסמכים המחייבים. הואיל ומדיניות הפרטיות יכולה להשתנות מדי
          פעם, מומלץ שתחזור ותעיין בה מעת לעת. מדיניות הפרטיות העדכנית תתפרסם בכל עת בגוף היישומון.
        </p>
        <p dir="rtl">
          <br />
          <strong>פרסומות</strong>
          <strong> </strong>
          <strong>ותכנים</strong>
          <strong> </strong>
          <strong>מסחריים</strong>
        </p>
        <p dir="rtl">
          מפעיל השירות עשוי להציב בשירות ובממשק היישומון, לפי שיקול דעתו הבלעדי, תכנים מסחריים, כגון
          פרסומות ומודעות, הנמסרים לפרסום מטעמם של מפרסמים שונים (לרבות פרסומות מטעם בתי עסק המציגים
          בשירות), המבקשים להציע למכירה מוצרים או שירותים, ובכלל זה מידע שמקורו בתומכים ונותני חסות.
        </p>
        <p dir="rtl">
          מפעיל השירות לא יישא בכל אחריות לתכנים המסחריים המתפרסמים בשירות מטעם צדדים שלישיים. מפעיל
          השירות אינו כותב, בודק, מוודא או עורך את תוכן הפרסומים הללו או אמיתותם. האחריות היחידה
          לתכנים המסחריים ולכל תוצאה שתיגרם משימוש בהם או מהסתמכות עליהם חלה על המפרסמים. פרסום
          תכנים מסחריים בשירות אינו מהווה המלצה או עידוד לרכוש את השירותים, הנכסים או המוצרים
          המוצעים בהם למכירה. כל עסקה שתיעשה בעקבות תכנים מסחריים המתפרסמים בשירות תסוכם ישירות בינך
          לבין המפרסם. מפעיל השירות אינו צד לכל עסקה כזו ולא יישא באחריות לשירותים וטובין שיוצעו
          בפרסומות המוצגות בשירות.
        </p>
        <p dir="rtl">
          <br />
          <strong>קניין</strong>
          <strong> </strong>
          <strong>רוחני</strong>
        </p>
        <p dir="rtl">
          כל זכויות הקניין הרוחני בשירות וביישומון, לרבות פטנטים, זכויות היוצרים, מדגמים, עיצובים
          וסודות המסחריים, הם רכושו של מפעיל השירות בלבד (למעט תכנים הנמסרים לפרסום על-ידי ומטעם בתי
          העסק), או של צדדים שלישיים אחרים שהתירו למפעיל השירות להשתמש בהם במסגרת השירות. זכויות אלה
          חלות בין היתר על שם המותג "PIKA", סימני המסחר המופיעים בשירות (בין אם נרשמו ובין אם לאו),
          הסמלים המסחריים בו, עיצובו הגרפי של היישומון, בסיסי הנתונים בו וכל תוכן אחר הכלול בשירות,
          לרבות קוד המחשב של השירות וכל פרט אחר הקשור בהפעלתו.
        </p>
        <p dir="rtl">
          אין להעתיק, לשנות, לפרסם, להפיץ, לשווק, לתרגם, להציג בפומבי, להעביר לציבור או להעמיד
          לרשותו, לעבד, ליצור או לעשות יצירות נגזרות, למכור, להשכיר או לעשות כל שימוש מסחרי בכל חלק
          מן הנ"ל, לרבות תמונות, קבצים גראפיים, טקסטים וקוד-מחשב, בין על ידך ובין באמצעות או בשיתוף
          צד שלישי, בכל דרך או אמצעי בין אם אלקטרוניים, ממוחשבים, מכאניים, אופטיים, אמצעי צילום או
          הקלטה, או בכל אמצעי ודרך אחרת, בלא קבלת הסכמה בכתב ומראש ממפעיל השירות או מבעלי הזכויות
          האחרים, לפי העניין ובכפוף לתנאי ההסכמה (ככל שתינתן).
        </p>
        <p dir="rtl">
          סימני המסחר ומודעות הפרסומת של מפרסמים בשירות ושל בתי העסק הם קניינם של מפרסמים ובתי עסק
          אלה בלבד. גם בהם אין לעשות שימוש בלא הסכמת המפרסם ו/או בית העסק בכתב ומראש.
        </p>
        <p dir="rtl">
          <br />
          <strong>הסרת</strong>
          <strong> </strong>
          <strong>תכנים</strong>
          <strong> </strong>
          <strong>מהשירות</strong>
        </p>
        <p dir="rtl">
          מפעיל השירות מכבד את זכויותיהם של אחרים, לרבות זכויות הקניין הרוחני של צדדים שלישיים כמו
          גם את זכותם לשם טוב ולפרטיות. מרבית התכנים בשירות מוזנים אליו על-ידי בתי העסק עצמם. במידה
          ואתה סבור כי תוכן כלשהו המתפרסם בשירות פוגע בזכויות כלשהן הנתונות לך, הנך מוזמן ומתבקש
          ליידע את מפעיל השירות על כך מיידית בכתב, באמצעות פרטי יצירת הקשר המצוינים בתחתית תנאים
          אלה. צעדים שיינקטו על-ידי מפעיל השירות, ככל שינקטו, בהסתמך על מידע שיועבר לידיו, לא יהוו
          הוכחה להפרת זכות כלשהי והם יינקטו בהתאם להוראות הדין.
        </p>
        <p dir="rtl">
          <br />
          <strong>ביטול</strong>
          <strong> </strong>
          <strong>עסקאות</strong>
          <strong> </strong>
          <strong>והחזרת</strong>
          <strong> </strong>
          <strong>מוצרים</strong>
        </p>
        <p dir="rtl">
          מפעיל השירות פועל בהתאם להוראות חוק הגנת הצרכן, התשמ"א-1981, בכל הנוגע לביטולה של עסקת מכר
          מרחוק, החזרת מוצרים וביטול שירותים ומוצרים שהוזמנו באמצעות השירות.
        </p>
        <p dir="rtl">
          מבלי לגרוע מהוראות כל דין, <strong>לא</strong>
          <strong> </strong>
          <strong>תתאפשר</strong>
          <strong> </strong>
          <strong>החזרה</strong>
          <strong> </strong>
          <strong>של</strong>
          <strong> </strong>
          <strong>מוצרים</strong>
          <strong> </strong>
          <strong>פסידים</strong>
          <strong>, </strong>
          <strong>לרבות</strong>
          <strong> </strong>
          <strong>מוצרי</strong>
          <strong> </strong>
          <strong>מזון</strong>
          <strong> </strong>
          <strong>ומשקה</strong>
          <strong> </strong>
          <strong>שנארזו</strong>
          <strong> </strong>
          <strong>מראש</strong>
          <strong> </strong>
          <strong>ושאינם</strong>
          <strong> </strong>
          <strong>בעלי</strong>
          <strong> </strong>
          <strong>חיי</strong>
          <strong> </strong>
          <strong>מדף</strong>
          <strong> </strong>
          <strong>ארוכים</strong>
          <strong> </strong>
          <strong>המאפשרים</strong>
          <strong> </strong>
          <strong>את</strong>
          <strong> </strong> <strong>החזרתם</strong>
          <strong>.</strong> תשומת ליבך כי מוצרי מזון ומשקה ומוצרים נוספים מחייבים החזקה ומשלוח
          בתנאים מבוקרים. בנוסף, לא תתאפשר החזרה של מוצרים שיוצרו או הוכנו במיוחד עבורך.
        </p>
        <p dir="rtl">
          אתה, מבצע ההזמנה (ולא מקבל המשלוח), רשאי לבטל עסקאות מכר מרחוק שביצעת באמצעות השירות
          ולהחזיר כל מוצר שרכשת מבתי עסק באמצעות השירות (כאמור, למעט מוצרים פסידים וכו'), באמצעות
          מסירת הודעת ביטול למפעיל השירות, תוך 14 יום מיום קבלת המוצר, או אישור ההזמנה, לפי המאוחר
          מביניהם.
        </p>
        <p dir="rtl">
          ככל ובתי העסק יציעו למכירה גם שירותים כאלה או אחרים, אזי בכל הנוגע לביטול שירותים שהוזמנו
          תוכל לבטל את השירות שהוזמן בתוך 14 ימים מיום ההזמנה או מיום קבלת אישור ההזמנה, לפי המאוחר
          מביניהם, ובלבד שביטול כאמור ייעשה לפחות שני ימי עסקים, שאינם ימי מנוחה, קודם למועד שבו
          אמור השירות להינתן.
        </p>
        <p dir="rtl">
          את הודעת הביטול תוכל למסור באמצעות כל אחת מדרכי יצירת הקשר המפורטות בתחתית תנאים אלה, או
          בכתובת מפעיל השירות המפורטת בכותרת התנאים.
        </p>
        <p dir="rtl">
          עליך להחזיר את המוצר למפעיל השירות על חשבונך, או לחילופין, לבקש ממפעיל השירות שיישלח שליח
          לאיסוף המוצר והחזרתו <strong>על</strong> <strong> </strong>
          <strong>חשבונך</strong> (מפעיל השירות רשאי לסרב לבקשה זו לפי שיקול דעתו). תשומת ליבך כי
          מפעיל השירות רשאית לתבוע את הצרכן בשל ירידה בערך המוצר כתוצאה מהרעה משמעותית במצבו.
        </p>
        <p dir="rtl">
          אם ביטול ההזמנה אינו בעקבות פגם במוצר או אי-התאמה, מפעיל השירות ישיב לך את כספך בתוך 14
          ימים מקבלת הודעת ביטול העסקה, בניכוי דמי ביטול בשיעור של 5% מהמחיר, או 100 ₪, לפי הנמוך
          מבין השניים. בעת ביטול הזמנה עקב פגם במוצר או אי-התאמה, לא ייגבו דמי ביטול, כספך יוחזר לך
          תוך 14 ימים ממועד קבלת הודעת הביטול, והמוצר יוחזר על חשבון מפעיל השירות. לא יינתן זיכוי
          כספי בגין מוצרים שלא שולמה בגינם תמורה כספית.
        </p>
        <p dir="rtl">
          במידה ואתה אדם עם מוגבלות, אזרח ותיק, או עולה חדש, אתה רשאי לפי החוק לבטל את ההזמנה בתוך
          ארבעה חודשים מיום עשייתה, מיום קבלת המוצר או מיום קבלת אישור ההזמנה, לפי המאוחר מביניהם,
          ובלבד שההתקשרות בעסקה כללה שיחה בינינו, לרבות באמצעות תקשורת אלקטרונית.
        </p>
        <p dir="rtl">
          <br />
          <strong>אחריות</strong>
        </p>
        <p dir="rtl">
          בכל מקום במסמכים המחייבים בו מפורטת אחריותו של מפעיל השירות, תהיה הכוונה גם לאחריותם של
          עובדיו, מנהליו והפועלים מכוחו, בשמו או עבורו.
        </p>
        <p dir="rtl">
          <br />
          <strong>השימוש</strong>
          <strong> </strong>
          <strong>בשירות</strong>
          <strong>, </strong>
          <strong>ביישומון</strong>
          <strong> </strong>
          <strong>ובכל</strong>
          <strong> </strong>
          <strong>תוכן</strong>
          <strong>, </strong>
          <strong>או</strong>
          <strong> </strong>
          <strong>שירות</strong>
          <strong> </strong>
          <strong>הכלולים</strong>
          <strong> </strong>
          <strong>בהם</strong>
          <strong> </strong>
          <strong>ייעשה</strong>
          <strong> </strong>
          <strong>על</strong>
          <strong> </strong>
          <strong>אחריותך</strong>
          <strong> </strong>
          <strong>הבלעדית</strong>
          <strong> </strong>
          <strong>והמלאה</strong>
          <strong> </strong>
          ומפעיל השירות לא יישא באחריות לכל נזק ישיר או עקיף שייגרמו לך, או למי מטעמך, או לכל צד
          שלישי אחר, לרבות כל הפסד, אובדן או הוצאה, הקשורים, או נובעים, במישרין או בעקיפין עם
          השירות, היישומון וכל הזמנה שתבצע מבתי העסק המציגים בשירות את מוצריהם, ובכלל זאת מההסתמכות
          על המידע שמפרסמים בתי עסק, או ביחס לטיב המוצרים שהם מציעים ותכונותיהם.
        </p>
        <p dir="rtl">
          שירותי PIKA ניתנים לשימוש כמות שהם (AS IS). לא ניתן להתאימם לצרכיו של כל אדם ואדם. אתה
          מאשר בזאת כי בדקת את השירות ומצאת אותו מתאים לצרכייך ומטרותייך. לא תהיה לך כל טענה, תביעה
          או דרישה כלפי מפעיל השירות בגין תכונות השירות, יכולותיהם, מגבלותיהם, הכללים הנוהגים לגביהם
          והתאמתם אליך ולצרכיך, כמו גם ביחס לטיב, איכות או תכונות המוצרים הנמכרים על-ידי בתי העסק.
        </p>
        <p dir="rtl">
          <br />
          <strong>כל</strong>
          <strong> </strong>
          <strong>החלטה</strong>
          <strong> </strong>
          <strong>שתקבל</strong>
          <strong> </strong>
          <strong>ביחס</strong>
          <strong> </strong>
          <strong>למידע</strong>
          <strong> </strong>
          <strong>שמפרסמים</strong>
          <strong> </strong>
          <strong>בתי</strong>
          <strong> </strong>
          <strong>העסק</strong>
          <strong> </strong>
          <strong>היא</strong>
          <strong> </strong>
          <strong>באחריותך</strong>
          <strong> </strong>
          <strong>המלאה</strong>
          <strong> </strong>
          <strong>בלבד</strong>
          <strong>. </strong>
          <strong>מפעיל</strong>
          <strong> </strong>
          <strong>השירות</strong>
          <strong> </strong>
          <strong>אינו</strong>
          <strong> </strong>
          <strong>מתחייבת</strong>
          <strong> </strong>
          <strong>כי</strong>
          <strong> </strong>
          <strong>מוצרים</strong>
          <strong> </strong>
          <strong>ושירותים</strong>
          <strong> </strong>
          <strong>שמציעים</strong>
          <strong> </strong>
          <strong>בתי</strong>
          <strong> </strong>
          <strong>העסק</strong>
          <strong> </strong>
          <strong>יהלמו</strong>
          <strong> </strong>
          <strong>את</strong>
          <strong> </strong>
          <strong>ציפיותיך</strong>
          <strong> </strong>
          <strong>ודרישותיך</strong>
          <strong>. </strong>
          <strong>מפעיל</strong>
          <strong> </strong>
          <strong>השירות</strong>
          <strong> </strong>
          <strong>לא</strong>
          <strong> </strong>
          <strong>יישא</strong>
          <strong> </strong>
          <strong>באחריות</strong>
          <strong> </strong>
          <strong>כלשהי</strong>
          <strong> </strong>
          <strong>לכל</strong>
          <strong> </strong>
          <strong>תוצאה</strong>
          <strong> </strong>
          <strong>שתנבע</strong>
          <strong> </strong>
          <strong>מרכישת</strong>
          <strong> </strong>
          <strong>מוצרים</strong>
          <strong> </strong>
          <strong>באמצעות</strong>
          <strong> </strong>
          <strong>השירות</strong>
          <strong>, </strong>
          <strong>או</strong>
          <strong> </strong>
          <strong>משימוש</strong>
          <strong> </strong>
          <strong>בהם</strong>
          <strong> </strong>
          <strong>או</strong>
          <strong> </strong>
          <strong>צריכתם</strong>
          <strong>. </strong>
          <strong>השימוש</strong>
          <strong> </strong>
          <strong>בשירות</strong>
          <strong> </strong>
          <strong>הוא</strong>
          <strong> </strong>
          <strong>על</strong>
          <strong> </strong>
          <strong>אחריותך</strong>
          <strong> </strong>
          <strong>בלבד</strong>
          <strong>. </strong>
        </p>
        <p dir="rtl">
          מפעיל השירות מעודד אותך להתייחס בזהירות ובביקורתיות לתכנים שמפרסמים בתי העסק בשירות. תכנים
          המוצגים בעמודי בתי העסק אינם מתפרסמים מטעמו של מפעיל השירות ולפיכך הוא אינו אחראי
          למהימנותם, אמינותם, דיוקם או שלמותם ואינו אחראי לכל תוצאה שתיגרם מהשימוש בהם או מהסתמכות
          עליהם. אנו דורשים כמובן מבתי העסק שהמידע והתכנים שהם מציגים, בכלל ובנוגע למוצרים בפרט,
          יהיה אמין ומדויק, אולם אין ביכולתנו להבטיח זאת.
        </p>
        <p dir="rtl">
          מפעיל השירות לא יישא בכל אחריות לכל נזק שייגרם לך בשל איחורים במשלוח, או כתוצאה מכך
          שהמוצרים שסופקו לך היו פגומים, אלא אם כן אירעו העיכוב או הפגם בשל רשלנות מצידו של מפעיל
          השירות. במקרה זה תהיה אחריות מפעיל השירות מוגבלת לסכום השווה למחיר הזמנתך.
        </p>
        <p dir="rtl">
          מפעיל השירות מתאמץ ועושה ככל שביכולתו לספק לך שירות תקין ובאיכות גבוהה. יחד עם זאת, מפעיל
          השירות אינו מתחייב ששירותי השירות לא יופרעו, יינתנו כסדרם או בלא הפסקות, יתקיימו בבטחה
          וללא טעויות, ויהיו חסינים מפני גישה בלתי-מורשית למחשבי מפעיל השירות או מפני נזקים,
          קלקולים, תקלות או כשלים - והכול, בחומרה, בתוכנה, בקווי ובמערכות תקשורת, אצל מפעיל השירות
          או אצל מי מספקיו.
        </p>
        <p dir="rtl">
          מפעיל השירות לא יישא באחריות לכל נזק הנובע מאובדן פרטייך, המידע בחשבונך או לשיבוש בפרטים
          אלה – בין באופן מלא ובין חלקי, כתוצאה מכשל במערכות המחשוב שלך או של מפעיל השירות, או של מי
          מטעמו, או של בתי העסק. מפעיל השירות לא יישא באחריות במקרה שבית עסק יעשה שימוש לרעה בפרטייך
          שנמסרו לו לצורך השלמת כל עסקה שנעשתה באמצעות השירות.
        </p>
        <p dir="rtl">
          <br />
          <strong>הפרה</strong>
          <strong> </strong>
          <strong>ושיפוי</strong>
        </p>
        <p dir="rtl">
          מבלי לגרוע מהוראות כל דין והמסמכים המחייבים, אתה מתחייב לשפות את מפעיל השירות, עובדיו,
          מנהליו או מי מטעמו, ו/או את בית העסק הרלבנטי, בגין כל נזק, הפסד, אבדן-רווח, תשלום או הוצאה
          שייגרמו להם - ובכלל זה שכ"ט עו"ד והוצאות משפט - עקב הפרת תנאי שימוש אלה או אילו מהמסמכים
          המחייבים. בנוסף, תשפה את מפעיל השירות, עובדיו, מנהליו או מי מטעמו, ו/או את בית העסק
          הרלבנטי, בגין כל טענה, תביעה או דרישה שתועלה נגדם על-ידי צד שלישי כלשהו כתוצאה מהפרתך את
          אילו מהוראות המסמכים המחייבים.
        </p>
        <p dir="rtl">
          <br />
          <strong>אחריות</strong>
          <strong> </strong>
          <strong>צדדים</strong>
          <strong> </strong>
          <strong>שלישיים</strong>
          <strong> </strong>
          <strong>ליישומון</strong>
        </p>
        <p dir="rtl">
          השימוש שלך ביישומון עשוי להיות כפוף להוראות חוזיות נוספות של צדדים שלישיים, המסדירות את
          השימוש בחנות המקוונת שממנה הורדת את היישומון, כגון Google Play. הוראות כאלה עשויות להיחשב
          גם כחוזה לטובת אותם צדדים שלישיים, אם הדבר נקבע כך באותן הוראות חוזיות נוספות של צדדים
          שלישיים. מכל מקום, אותם צדדים שלישיים אינם אחראים למתן תמיכה או תחזוקה ליישומון.
        </p>
        <p dir="rtl">
          ההוראות שלהלן חלות רק אם הורדת את היישומון מהאפ-סטור של אפל (Apple App Store), שאז אתה
          ומפעיל השירות מסכימים כי -
        </p>
        <p dir="rtl">
          המסמכים המחייבים נכרתים בינך לבין מפעיל השירות, לא עם חברת .Apple Inc ("{' '}
          <strong>אפל</strong>"). אפל אינה אחראית ליישומון. במקרה של סתירה או אי-התאמה בין המסמכים
          המחייבים לבין תנאי השירות של האפ-סטור, הוראות תנאי השירות של האפ-סטור יגברו, אולם רק ביחס
          להוראות שנוגעות לאי-ההתאמה או לסתירה.
        </p>
        <p dir="rtl">
          הרישיון שמוענק לך ביישומון מוגבל לרישיון שאינו ניתן להעברה, לעשות שימוש ביישומון בכל מוצרי
          iOS שבבעלותך או בשליטתך, על-פי תנאי השימוש (Usage Rules) הקבועים בתנאי השירות של האפ-סטור.
        </p>
        <p dir="rtl">
          במקרה של אי-עמידה באחריות מוצר כלשהו (אם אחריות כזו חלה), אתה רשאי להודיע לאפל ואפל תזכה
          אותך במחיר הורדת היישומון (אם שילמת עליו). אין לאפל אחריות מוצר אחרת מכל סוג שהוא ביחס
          ליישומון וכל הטענות, הפסדים, חבויות, נזקים, עלויות והוצאות המיוחסות לאי-עמידה באחריות
          המוצר (אם חלה), לא תהיה באחריות אפל.
        </p>
        <p dir="rtl">
          אפל אינה אחראית לטענות שלך או של צד שלישי כלשהו ביחס ליישומון, לאחזקתך בו או לשימוש שלך
          בו, לרבות: (א) טענות בנוגע לאחריות מוצר; (ב) טענו לפיהן היישומון אינו עומד בדרישות חוקיות
          או רגולטוריות כלשהן; (ג) טענות הנובעות מחקיקת הגנת הצרכן או חקיקה דומה.
        </p>
        <p dir="rtl">
          במקרה של טענת צד שלישי לפיה היישומון, אחזקתך בו או שימושך בו, מפר את זכויות הקניין הרוחני
          של צד שלישי, אפל לא תהיה אחראית לחקור, להגן, להתפשר או לסלק תביעות הפרה כאלה.
        </p>
        <p dir="rtl">
          עליך לציית לכל הסכם תנאי שימוש של צד שלישי החלים עליך בקשר לשימושך ביישומון (למשל, אסור לך
          להפר את תנאי השירות של ספק התקשורת האלחוטית שלך בעודך משתמש ביישומון).
        </p>
        <p dir="rtl">
          אפל וחברות-הבת שלה הם מוטבי צד-שלישי של תנאים אלה. בהסכמתך לתנאים אלה, לאפל תהיה הזכות
          (ותחשב כמי שקיבלה את הזכות) לאכוף את התנאים כלפיך, וזאת בתור מוטב צד שלישי לתנאים. אתה
          מצהיר כי: (א) אינך ממוקם במדינה הנתונה לאמברגו של ממשלת ארה"ב, או שסומנה על-ידי ממשלת
          ארה"ב כמדינה תומכת טרור; (ב) אינך מופיע ברשימת הגורמים הפסולים או מוגבלים של ממשלת ארה"ב.
        </p>
        <p dir="rtl">
          <br />
          <strong>שינויים</strong>
          <strong> </strong>
          <strong>בשירות</strong>
          <strong> </strong>
          <strong>והפסקת</strong>
          <strong> </strong>
          <strong>פעילות</strong>
        </p>
        <p dir="rtl">
          מפעיל השירות רשאי לשנות מעת לעת את מבנה השירות, מראהו ועיצובו, את היקפם וזמינותם של
          השירותים, התכנים ואפשרויות הפרסום בו ויהיה רשאי לשנות כל היבט אחר הכרוך בשירות - והכול,
          בלא צורך להודיע לך על כך מראש. שינויים כאלה יבוצעו, בין השאר, בהתחשב באופי הדינאמי של
          האינטרנט ובשינויים הטכנולוגיים והאחרים המתרחשים בו. מטבעם, שינויים מסוג זה עלולים להיות
          כרוכים בתקלות או לעורר בתחילה אי-נוחות וכיו"ב. לא תהיה לך כל טענה, תביעה או דרישה כלפי
          מפעיל השירות בגין ביצוע שינויים כאמור או תקלות שיתרחשו אגב ביצועם.
        </p>
        <p dir="rtl">
          מפעיל השירות יהיה רשאי להפסיק בכל עת את מתן השירות, כולו או מקצתו, באופן זמני או קבוע.
          מפעיל השירות אף יהיה רשאי להפסיק מפעם לפעם את הפעילות בשירות לשם תחזוקה, תיקון, שדרוג
          ושיפור השירות. מפעיל השירות יפעל כמיטב יכולתו על-מנת לצמצם את פרקי הזמן שבמהלכם השירות לא
          יהיה פעיל. במידת האפשר, יפרסם מפעיל השירות הודעה בשירות על הפסקת השירות זמן סביר מראש. עם
          הפסקת השירות יחזיק מפעיל השירות את התוכן הכלול בשירות למשך זמן סביר נוסף ולאחר מכן יהיה
          רשאי למחוק אותו בלא לשמור כל גיבוי ממנו ובלא לתת הודעה נוספת על כך.
        </p>
        <p dir="rtl">
          <br />
          <strong>שינוי</strong>
          <strong> </strong>
          <strong>במסמכים</strong>
          <strong> </strong>
          <strong>המחייבים</strong>
        </p>
        <p dir="rtl">
          מפעיל השירות רשאי לשנות מעת לעת את הוראות תנאי שימוש אלה וכל אחד מהמסמכים המחייבים. במידת
          האפשר ואם יבוצעו בתנאי שימוש אלה או במסמכים המחייבים שינויים מהותיים, תפורסם על-כך הודעה
          בממשק היישומון. הקישורים למסמכים המחייבים המוצבים בשירות יקשרו תמיד לנוסח העדכני של
          המסמכים המחייבים. המשך שימושך בשירות לאחר פרסום המסמכים המחייבים המעודכנים, מעיד על הסכמתך
          להם. אם אינך מסכים עם ההוראות החדשות במסמכים המחייבים, עלייך לחדול מלעשות כל שימוש בשירות.
        </p>
        <p dir="rtl">
          <br />
          <strong>דין</strong>
          <strong> </strong>
          <strong>וסמכות</strong>
          <strong> </strong>
          <strong>שיפוט</strong>
        </p>
        <p dir="rtl">
          על השימוש ביישומון ובשירות, ועל כל הזמנה שתבצע באמצעותם, יחולו אך ורק דיני מדינת ישראל.
          מקום השיפוט הבלעדי בגין כל דבר ועניין הנובע מהמסמכים המחייבים, הינו בבתי המשפט המוסמכים
          בעיר נצרת (מחוז צפון), ישראל.
        </p>
        <p dir="rtl">
          <br />
          <strong>המחאה</strong>
        </p>
        <p dir="rtl">
          העביר מפעיל השירות את זכויותיו והתחייבויותיו ביחס לשירות, או כל חלק ממנו, לצד שלישי, הוא
          יהיה רשאי להמחות גם את זכויותיו והתחייבויותיו, כולן או חלקן, לפי המסמכים המחייבים. בפרט,
          מפעיל השירות יהיה רשאי להמחות את זכויותיו והתחייבויותיו כאמור לתאגיד שירשום. כמשתמש
          בשירות, אינך רשאי להמחות כל זכות או חיוב לפי המסמכים המחייבים וכן אינך רשאי לשעבד, להסב,
          למשכן, להשאיל או לתת זכויות כלשהן לכל צד שלישי בכל אלה. כל מעשה או מחדל בניגוד לאמור לעיל
          – בטלים.
        </p>
        <p dir="rtl">
          <br />
          <strong>פנה</strong>
          <strong> </strong>
          <strong>אלינו</strong>
        </p>
        <p dir="rtl">
          מפעיל השירות מקפיד על קיום הוראות החוק ומכבד את זכויותיהם של משתמשי השירות וכל צד שלישי
          אחר. אם אתה סבור כי פורסם בשירות תוכן הפוגע בך מטעם כלשהו, וכן במידה ויש לך כל שאלה, הצעה,
          תלונה ועוד, אנא פנה אלינו לפי הפרטים שלהלן, או באמצעות טופס יצירת הקשר [
          <strong>Pikadelivery.com/contact</strong>] בגוף השירות, ואנו נשתדל לטפל בפנייתך בכל ההקדם:{' '}
          <strong>פיקה</strong>
          <strong> </strong>
          <strong>שירותי</strong>
          <strong> </strong>
          <strong>שליחויות</strong>
          <strong> </strong>
          <strong>בע</strong> <strong>"</strong>
          <strong>מ</strong>, רחוב 1006 מספר 35, נצרת 1623729, טלפון: 0546665896; דוא"ל:
          info@pikadelivery.com;
        </p>
        <p dir="rtl">
          יש להימנע מלשלוח למפעיל השירות פניות הכוללות תוכן שהוא בלתי חוקי, בעל אופי מיני בוטה,
          טורדני, מעליב, עוין, מזיק, מאיים, גס, גזעני, פוגע ברגשות הציבור, מעודד ביצוע עבירה פלילית,
          עלול להוות בסיס לתביעה או אחריות אזרחית, מוציא לשון הרע, פוגע בפרטיות או מהווה הפרה אחרת
          של הדין. מפעיל השירות שומר על זכותו שלא לסייע למי שפונה אליו בפנייה בעלת אופי כאמור.
        </p>
        <p dir="rtl">
          <br />
          <strong>עודכן</strong>
          <strong> </strong>
          <strong>לאחרונה</strong>
          <strong>: DATE \@ "dddd dd MMMM yyyy" 25.04.2021</strong>
        </p>
      </div>
    </div>
  );
};

export default Terms;

const styles = {
  text: {
    color: 'white',
    fontSize: 150,
  },
  container: {
    backgroundImage: `url(${background})`,
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    justifyContent: 'center',
  },
  innerContainer: {
    paddingTop: 100,
    maxWidth: 900,
  },
};
