import React,{useState} from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import '../form.css';
import axios from 'axios';
import {Link} from 'react-router-dom'
import regex from '../../misc/regex';
import {Helmet} from "react-helmet";

const DeliveryForm = () => {



    const DeliverySchema = Yup.object().shape({
          email: Yup.string().email('אימייל לא תיקני').required('חובה להזין אימייל'),
          fullName: Yup.string().required('חובה להזין שם '),
          phone: Yup.string().matches(regex.phone,"טלפון לא תיקני"),
          restaurantName: Yup.string().required('חובה להזין שם מסעדה'),
          restaurantAdress: Yup.string().required('חובה להזין כתובת מסעדה'),
          terms: Yup.bool().oneOf([true],'חובה לאשר את התנאים'),
      });

      const [submitted, setSubmitted] = useState(false);
      const [errorEmail, setErrorEmail] = useState(false);


    return (
        <div className='delivery page about'>
        <Helmet>
        <title>Pika - Businesses</title>
        </Helmet>
        <h1>הפוך לעסק שותף</h1>
        <div className='form-desc'>
          <p>תודה שהתעניינתם בPika <br />  אנא מלאו את הטופס ואנחנו ניצור איתכם קשר תוך שבעה ימים </p>
        </div>
        <Formik
          initialValues={{
            formType: 'business',
            fullName: '',
            email: '',
            phone: '',
            restaurantName: '',
            restaurantAdress: '',
            restaurantUrl: '',
            terms: false,
            picked: '',
          }}
          validationSchema={DeliverySchema}
          onSubmit={(values) => {
            const headers = {
              // 'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              // 'Access-Control-Allow-Methods': 'GET, POST',
              'Access-Control-Allow-Headers': '*',
              'api-key': 'e05569c9-c10b-4922-8b94-0a962c1bb22e'
            }
            const vals =  (({ terms, ...o }) => o)(values);
            console.log(vals)

            const urli = 'https://api.pikadelivery.com/contact-form';
            //send the object to email
            axios.post(urli,{...vals},{headers:headers}).then((response) =>{console.log(response)}).catch((error) => {setSubmitted(false); setErrorEmail(true)});
              if(errorEmail === true){
                setSubmitted(true);
              } 

          }}
          validator={() => ({})}
        >
          {({ errors, touched }) => (
            <Form >

            <div className="contact-form form-group col" id="contact">
              <label>שם מלא <span className="req-sign">*</span></label>
                <Field name="fullName" />
                {errors.fullName && touched.fullName ? (
                  <div className='red'>{errors.fullName}</div>
                ) : null}

                <label>אימייל <span className="req-sign">*</span></label>
                <Field name="email" type="email" />
                {errors.email && touched.email ? (
                  <div className='red'>{errors.email}</div>
                ) : null}
              </div>

              <div className="contact-form form-group col">
                <label>מספר טלפון <span className="req-sign">*</span></label>
                <Field name="phone" />
                {errors.phone && touched.phone ? <div className='red'>{errors.phone}</div> : null}

                <label> שם המסעדה <span className="req-sign">*</span></label>
                <Field name="restaurantName" />
                {errors.restaurantName && touched.restaurantName ? <div className='red'>{errors.restaurantName}</div> : null}

                <label> כתובת המסעדה <span className="req-sign">*</span></label>
                <Field name="restaurantAdress" />
                {errors.restaurantAdress && touched.restaurantAdress ? <div className='red'>{errors.restaurantAdress}</div> : null}

                <label>אתר המסעדה </label>
                <Field name="restaurantUrl" />
                {errors.restaurantUrl && touched.restaurantUrl ? <div className='red'>{errors.restaurantUrl}</div> : null}



              </div>

              <div className="contact-form form-group col radio">
                  <p className='form-desc'>האם יש לכם שירות משלוחים פנימי?</p>
                  <div role="group" aria-labelledby="inner-delivery">
                    <label>
                    <div className="radio-row">
                    <span className="radio-span">כן</span>
                      <Field type="radio" name="picked" value="yes" />
                    </div>
                    </label>
                    <label>
                    <div className="radio-row">
                    <span className="radio-span">לא</span>
                      <Field type="radio" name="picked" value="no" />
                      </div>
                    </label>
                </div>
              <div>

                  
                
              </div>

              </div>

              <div className="contact-form form-group cols">
              <div>
                <label>
                <Field type="checkbox" name="terms" />
                קראתי ואני מאשר את <Link to="/terms" style={{color: '#000',textDecoration: 'underline'}}>מדיניות הפרטיות</Link>
                </label>
                <br />
                <span className="red">{errors.terms && touched.terms ? <div>{errors.terms}</div> : null}</span>
              </div><br />



              <button className="btn btn-submit" type="submit" disabled={submitted}>שלח</button><br />
              {submitted && 'הטופס נשלח בהצלחה'}
              <span className="red">{errorEmail && 'שגיאה בשליחת הטופס, אנא נסה שוב מאוחר יותר'}</span>
              
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
}

export default DeliveryForm;
