import React,{useState} from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import '../form.css';
import axios from 'axios';
import {Link} from 'react-router-dom'
import {Helmet} from "react-helmet";

const DeliveryForm = () => {

  const phoneRegex = /^\+?(972|0)(\-)?0?(([23489]{1}\d{7})|[57]{1}\d{8})$/; //eslint-disable-line

  const DeliverySchema = Yup.object().shape({
        email: Yup.string().email('אימייל לא תיקני').required('חובה להזין אימייל'),
        fullName: Yup.string().required('חובה להזין שם '),
        phone: Yup.string().matches(phoneRegex,"טלפון לא תיקני"),
        city: Yup.string().required('חובה להזין עיר'),
        terms: Yup.bool().oneOf([true],'חובה לאשר את התנאים'),
    });


    const [submitted, setSubmitted] = useState(false);
    const [errorEmail, setErrorEmail] = useState(false);


    return (
        <div className='delivery page'>
        <Helmet>
        <title>Pika - Delivery</title>
        </Helmet>
        <h1>שליחים? הצטרפו אלינו</h1>
        <p>אם אתם רוצים להרוויח יותר ולנסוע פחות, הגעתם למקום הנכון. אנחנו מחפשים שליחים בעלי קטנוע, אופניים, או קורקינט לאזורי החלוקה שלנו</p>
        <Formik
          initialValues={{
            formType: 'delivery',
            fullName: '',
            email: '',
            phone: '',
            city: '',
            isFreelance: '',
            terms: false,
          }}
          validationSchema={DeliverySchema}
          onSubmit={(values) => {
            const headers = {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Methods': 'GET, POST',
              'Access-Control-Allow-Headers': '*',
              'api-key': 'e05569c9-c10b-4922-8b94-0a962c1bb22e'
            }
            const urli = 'https://api.pikadelivery.com/contact-form';
            const vals =  (({ terms, ...o }) => o)(values);
            //send the object to email
            axios.post(urli,{...vals},{headers:headers}).then((response) =>{console.log(response)}).catch((error) => {setSubmitted(false); setErrorEmail(true)});
              if(errorEmail){
                setSubmitted(true);
              } 
          }}
        >
          {({ errors, touched }) => (
            <Form>

            <div className="contact-form form-group col" id="contact">
              <label>שם מלא <span className="req-sign">*</span></label>
                <Field name="fullName" />
                {errors.fullName && touched.fullName ? (
                  <div className="red">{errors.fullName}</div>
                ) : null}

                <label>אימייל <span className="req-sign">*</span></label>
                <Field name="email" type="email" />
                {errors.email && touched.email ? (
                  <div className="red">{errors.email}</div>
                ) : null}
              </div>

              <div className="contact-form form-group col">
                <label>מספר טלפון <span className="req-sign">*</span></label>
                <Field name="phone" />
                {errors.phone && touched.phone ? <div className="red">{errors.phone}</div> : null}

                <label>עיר מגורים <span className="req-sign">*</span></label>
                <Field name="city" />
                {errors.city && touched.city ? <div className="red">{errors.city}</div> : null}
              </div>

              <div className="contact-form form-group col">
                <Field name="isFreelance" as="select">
                  <option value="yes-patur">כן, אני עוסק פטור</option>
                  <option value="yes-murshe">כן, אני עוסק מורשה</option>
                  <option value="no">לא, אין לי עוסק עצמאי</option>
                </Field>



              <div>

                  
                {errors.isFreelance && touched.isFreelance ? <div>{errors.isFreelance}</div> : null}
              </div>

              </div>

              <div className="contact-form form-group cols">
              <div>
                <label>
                <Field type="checkbox" name="terms" />
                קראתי ואני מאשר את <Link to="/terms" style={{color: '#000',textDecoration: 'underline'}}>מדיניות הפרטיות</Link>
                </label>
                <br />
                <span className="red">{errors.terms && touched.terms ? <div>{errors.terms}</div> : null}</span>
              </div><br />


                <button className="btn btn-submit" type="submit" disabled={submitted}>שלח</button><br />
                {submitted && 'הטופס נשלח בהצלחה'}
                <span className="red">{errorEmail && 'שגיאה בשליחת הטופס, אנא נסה שוב מאוחר יותר'}</span>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
}

export default DeliveryForm;
