import React from 'react';
import Background from '../../assets/BackgroundMiddle-min.png';
import './WithLoveForYou.css';

const WithLoveForYou = () => {
    return (
        <div className="background-middle-section" style={{backgroundImage: `url('${Background}')`}}>
            <h1>
            נעשה 
            </h1>
            <h1>
            באהבה
            </h1>
            <h1>
            בשבילך
            </h1>
        </div>
    );
}

export default WithLoveForYou;
