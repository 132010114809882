import React from 'react';
import AboutImage from '../../assets/AboutImage-min.png';
// import PlayIcon from '../../assets/PlayIcon.svg';
import './About.css';

const About = () => {
    return (
        <div className='section about'>
            <div className='image-container'>
                <img src={AboutImage} alt="about" />
            </div>
            <div className='about-title'>
                <h2>מי אנחנו?</h2>
                <p>
                    <span>אנחנו Pika.</span><br />
                    <span>אנו כאן כדי להפוך את תהליך הזמנת האוכל עבורך לקל זמין וללא מאמץ</span>
                </p>

                <h4>אנחנו אוהבים אוכל ורוצים לחלוק איתך את האהבה הזו!</h4>
            </div>
        </div>
    );
}

export default About;
