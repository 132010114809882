import React from 'react';
import './JoinTheTeam.css';

import BusinessImage from '../../assets/BusinessBoxImage-min.png';
import DelivryImage from '../../assets/DeliveryBoxImage.svg';
import {Link} from 'react-router-dom';


const JoinTheTeam = () => {
    return (
        <div className='section join'>
            <div className='join-title'>
                <p>בואו להיות חלק מאיתנו</p>
            </div>
            <div className='join-boxes'>
                <div className='join-business box'>
                    <div className='box-image'>
                        <img src={BusinessImage} alt='Join as business' />
                        <div className='box-title'>
                            <h3>הפוך לעסק שותף</h3>
                        </div>
                        <div className='box-link'>
                        <h3><Link to="/Delivery/business">הגש המועמדות</Link></h3>
                    </div>
                    </div>


                </div>

                <div className='join-delivery box'>
                    <div className='box-image'>
                        <img src={DelivryImage} alt='join as delivery' />
                        <div className='box-title'>
                            <h3>אנחנו מחפשים שליחים</h3>
                        </div>
                        <div className='box-link'>
                        <h3><Link to="/Delivery/biker">הגש המועמדות</Link></h3>
                    </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default JoinTheTeam;
