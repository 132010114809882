import React from 'react';
import './Footer.css';
import FooterBg from '../../assets/FooterBackground.svg';
import LogoText from '../../assets/LogoText.svg';
import AppStoreIcon from '../../assets/AppStoreIcon.svg';
import GooglePlayIcon from '../../assets/GooglePlayIcon.svg';
import FacebookIcon from '../../assets/FacebookIcon.svg';
import InstaIcon from '../../assets/InstaIcon.svg';
import {Link} from 'react-router-dom';
import './Footer.css';

const Footer = () => {
    return (
        <div className="footer-section">
            <div className="footer-background" style={{backgroundImage: `url(${FooterBg})`, overflow: 'hidden'}}>
                <div className="footer-logo">
                    <img src={LogoText} alt='Pika logo' />
                    <h2>לא במצב רוח לבישול?</h2>
                    <p className='description'>
                    הורידו את האפליקציה שלנו, היא זמינה עבור האפל ואנדרויד. גלו את עולם האוכל הטעים, בחרו מה אתם רוצים - אנו מספקים.
                    </p><br />
                    <p className='store-icons'>
                        <a href="/"><img src={AppStoreIcon} alt="app store" /></a>
                        <a href="https://play.google.com/store/apps/details?id=com.branded.pika"><img src={GooglePlayIcon} alt="google store" /></a>
                    </p>
                    <div>
                        <p className='media-icons'>
                            <a href="https://www.facebook.com/Pika-Delivery-353405146076785/" rel="noreferrer" target="_blank"><img src={FacebookIcon} alt="facebook" /></a>
                            <a href="https://instagram.com/pika.app1?igshid=8b7jscfw6j5d" rel="noreferrer" target="_blank"><img src={InstaIcon} alt="Instagram" /></a>
                        </p>
                    </div>
                    <div className="footer-links">
                    <Link to="/terms">מדיניות פרטיות</Link>
                    <Link to="/privacy">תנאי שימוש</Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;
//https://play.google.com/store/apps/details?id=com.branded.pika