import React from 'react';
import LogoImage from '../../assets/LogoImage-min.png';
import './Logo.css';

const Logo = () => {
    return (
        <div className='section logo'>
        <img src={LogoImage} alt="logo" />
        <div className='logo-title'>
            <h1>You Choose</h1>
            <h1>We Deliver</h1>
        </div>
        </div>
    );
}

export default Logo;
