import React from 'react';
import Logo from '../Logo/Logo';
import DeliveryForm from '../DeliveryForm/DeliveryForm';
import BusinessForm from '../BusinessForm/BusinessForm';
import background from '../../background.svg';
import {useParams} from 'react-router-dom';



const DeliveryModal = () => {

    const {form} = useParams();

    return (
        <div>
        <div style={styles.container} className='homeidv'>
        <Logo />
        </div>
            {form === "biker" ? <DeliveryForm /> : <BusinessForm />}
        </div>
    );
}

export default DeliveryModal;

        const styles = {
            container: {
              height: '100vh',
              overflow: 'hidden',
              backgroundImage: `url(${background})`,
            },
          };