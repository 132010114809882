import background from '../background.svg';
import Logo from '../components/Logo/Logo';
import About from '../components/About/About';
import WithLoveForYou from '../components/WithLoveForYou/WithLoveForYou';
import JoinTheTeam from '../components/JoinTheTeam/JoinTheTeam';
import Contact from '../components/Contact/Contact';

const Home = () => {
  return (
    <div>
      <div style={styles.container} className='homeidv'>
        <Logo />
      </div>
      <About />
      <WithLoveForYou />
      <JoinTheTeam />
      <Contact />
    </div>
  );
};

export default Home;

const styles = {
  container: {
    overflow: 'hidden',
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover'
  },
};
